import React from 'react';

import css from './TopbarSearchForm.module.css';

const IconSearchDesktop = () => (
  <svg
    className={css.iconSvg}
    width="32"
    height="34"
    viewBox="0 0 32 34"
    fill="none"
    style={{ fill: 'transparent' }}
  >
    <path
      d="M9.78949 23.6333L1.23818 32.1065"
      stroke="#4A4A4A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{ stroke: `var(--marketplaceInitialDefaultColor)` }}
    />
    <ellipse
      cx="12.2368"
      cy="12.125"
      rx="12.2368"
      ry="12.125"
      transform="matrix(-1 0 0 1 31 1)"
      stroke="#4A4A4A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{ stroke: `var(--marketplaceInitialDefaultColor)` }}
    />
  </svg>
);

export default IconSearchDesktop;
