import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { arrayOf, array, bool, func, node, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import StarRatings from 'react-star-ratings';
import config from '../../config';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import {
  propTypes,
  LISTING_STATE_CLOSED,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  LINE_ITEM_UNITS,
  FOR_SALE,
  SIZE_TYPE,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import { userDisplayNameAsString } from '../../util/data';
import { ModalInMobile, Button, AvatarSmall, AvatarMedium } from '../../components';

import BookingDatesForm from './BookingDatesForm/BookingDatesForm';
import ProductOrderForm from './ProductOrderForm/ProductOrderForm';
import css from './OrderPanel.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { updateListingData } from '../../containers/ListingPage/ListingPage.duck';
import { getUserDetails } from '../../util/dataExtractor';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routing/routeConfiguration';

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const calculateAverage = reviews => {
  if (reviews?.length === 0) {
    return 0;
  }
  const sum =
    reviews &&
    reviews?.reduce((accumulator, currentValue) => accumulator + currentValue.attributes.rating, 0);
  const average = sum / reviews?.length;
  return average;
};

export const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openOrderModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), orderOpen: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeOrderModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'orderOpen');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const OrderPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    author,
    onManageDisableScrolling,
    timeSlots,
    fetchTimeSlotsError,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    isReportModalOpen,
    addToCartAndRemoveFromCart,
    reviews,
    makeAnOfferButton,
    isTransactionPanel,
    transaction,
    currentUser,
    isStripeConnected,
  } = props;

  const state = useSelector(state => state);
  const {authorData} = state.ListingPage;  
  const {sellerShippingAddress =[]} = !!authorData?.id && authorData.attributes.profile.protectedData || {};
  
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const isUnits = unitType === LINE_ITEM_UNITS;
  const shouldHaveBooking = isNightly || isDaily;
  const dispatch = useDispatch();
  const { notifiedUser = [], availability } = listing.attributes.publicData;
  const price = listing.attributes.price;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingDatesForm = shouldHaveBooking && hasListingState && !isClosed;
  const showClosedListingHelpText = listing.id && isClosed;
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const isOrderOpen = !!parse(location.search).orderOpen;
  const { listingPrice, offerPrice, isOfferTransaction, counterOfferDetails = [] } =
    transaction?.attributes?.protectedData || {};
  const lastIndex = counterOfferDetails && counterOfferDetails.length - 1;
  const counterPrice =
    counterOfferDetails[lastIndex] && counterOfferDetails[lastIndex].counterPrice;
  const currentUserEmail = !!currentUser?.id && getUserDetails(currentUser)?.email;
  const currentUserName = !!currentUser?.id && getUserDetails(currentUser)?.firstName;
  // The listing resource has a relationship: `currentStock`,
  // which you should include when making API calls.
  const currentStock = listing.currentStock?.attributes?.quantity;
  const isOutOfStock = config.listingManagementType === 'stock' && currentStock === 0;

  // Show form only when stock is fully loaded. This avoids "Out of stock" UI by
  // default before all data has been downloaded.
  const showProductOrderForm = true;

  const { pickupEnabled, shippingEnabled, size, brand ,customSize} = listing?.attributes?.publicData || {};

  const subTitleText = showClosedListingHelpText
    ? intl.formatMessage({ id: 'OrderPanel.subTitleClosedListing' })
    : null;

  const unitTranslationKey = isNightly
    ? 'OrderPanel.perNight'
    : isDaily
    ? 'OrderPanel.perDay'
    : 'OrderPanel.perUnit';

  const authorDisplayName = userDisplayNameAsString(author, '');

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.orderTitle);

  return (
    <div className={classes}>
      {/* <ModalInMobile
        containerClassName={css.modalContainer}
        id="BookingDatesFormInModal"
        isModalOpenOnMobile={isOrderOpen}
        onClose={() => closeOrderModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      > */}
      {/* <div className={css.modalHeading}>
          <h1 className={css.title}>{title}</h1>
        </div> */}
      {isTransactionPanel && isOfferTransaction ? (
        <div className={css.priceContainer}>
          <h1 className={css.title}>{title}</h1>
          <div className={css.priceValue} title={priceTitle}>
            <FormattedMessage id="OrderPanel.sizeText" />
            {size === SIZE_TYPE ?customSize :size} {brand && <>| {brand}</>}
          </div>
          <div className={css.priceList}>
            <span className={css.listingPrice}>
              {' '}
              <FormattedMessage id="OrderPanel.listingPrice" values={{ listingPrice }} />
            </span>
            <span className={css.offerPrice}>
              <FormattedMessage id="OrderPanel.offerPrice" values={{ offerPrice }} />{' '}
            </span>
            {counterPrice && (
              <FormattedMessage id="OrderPanel.counterPrice" values={{ counterPrice }} />
            )}
          </div>
        </div>
      ) : (
        <div className={css.priceContainer}>
          <h1 className={css.title}>{title}</h1>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice} {price.currency} | <FormattedMessage id="OrderPanel.sizeText" />
            {size === SIZE_TYPE ? customSize :size} {brand && <>| {brand}</>}
          </div>
          <div className={css.author}>
            <AvatarMedium user={author} className={css.providerAvatar} />
            <div>
              <div className={css.authorName}>{authorDisplayName} </div>
              <div className={css.starRatings}>
                <StarRatings
                  svgIconViewBox="0 0 40 37"
                  svgIconPath="M20 0L26.113 11.5862L39.0211 13.8197L29.891 23.2138L31.7557 36.1803L20 30.4L8.2443 36.1803L10.109 23.2138L0.97887 13.8197L13.887 11.5862L20 0Z"
                  starRatedColor="#ffb802"
                  // starEmptyColor="#ffffff"
                  rating={reviews?.length ? calculateAverage(reviews) : 0}
                  starDimension="25px"
                  starSpacing="2px"
                />
              </div>
            </div>
            {isOwnListing ? null : (
              <div className={css.orderColumnForProductLayout}>
                <p
                  onClick={() => {
                    isReportModalOpen();
                  }}
                  className={css.reportButton}
                >
                  <FormattedMessage id="ListingPage.reportBug" />
                </p>
              </div>
            )}
          </div>
        </div>
      )}
      {availability === FOR_SALE && (
        <>
          {showBookingDatesForm ? (
            <BookingDatesForm
              className={css.bookingForm}
              formId="OrderPanelBookingDatesForm"
              submitButtonWrapperClassName={css.bookingDatesSubmitButtonWrapper}
              unitType={unitType}
              onSubmit={onSubmit}
              price={price}
              listingId={listing.id}
              isOwnListing={isOwnListing}
              timeSlots={timeSlots}
              fetchTimeSlotsError={fetchTimeSlotsError}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              lineItems={lineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
            />
          ) : showProductOrderForm ? (
            <ProductOrderForm
              formId="OrderPanelProductOrderForm"
              onSubmit={onSubmit}
              isStripeConnected={isStripeConnected}
              transaction={transaction}
              price={price}
              currentStock={currentStock}
              pickupEnabled={pickupEnabled}
              shippingEnabled={shippingEnabled}
              listingId={listing.id}
              isOwnListing={isOwnListing}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              onContactUser={onContactUser}
              lineItems={lineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              listing={listing}
              currentUser={currentUser}
            />
          ) : null} {(!sellerShippingAddress?.length > 0 || currentStock === 0 ) && <br/>}
          {/* </ModalInMobile> */}
          {isOwnListing || !sellerShippingAddress?.length > 0 || currentStock === 0  ? null : addToCartAndRemoveFromCart}
          {isOwnListing || !sellerShippingAddress?.length > 0 || currentStock === 0 ? null : makeAnOfferButton}
          {isOwnListing || transaction ? null : (
            <>
              {!notifiedUser.find(user => user.currentUserEmail === currentUserEmail) ? (
                <Button
                  onClick={() => {
                    if (!currentUser) {
                      const state = {
                        from: `${location.pathname}${location.search}${location.hash}`,
                      };
                      history.push(
                        createResourceLocatorString('LoginPage', routeConfiguration(), {}, {}),
                        state
                      );
                    } else {
                      !!currentUser?.id && notifiedUser.push({ currentUserEmail, currentUserName });
                      const listingdata = {
                        listingId: listing.id,
                        userId: listing.author.id,
                        notifiedUser,
                      };
                      dispatch(updateListingData(listingdata));
                    }
                  }}
                >
                  <FormattedMessage id="OrderPanel.notify" />
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    const index =
                      notifiedUser &&
                      notifiedUser.findIndex(b => b.currentUserEmail === currentUserEmail);
                    !!currentUser?.id && notifiedUser.splice(index, 1);
                    const listingdata = {
                      listingId: listing.id,
                      userId: listing.author.id,
                      notifiedUser,
                    };
                    dispatch(updateListingData(listingdata));
                  }}
                >
                  <FormattedMessage id="OrderPanel.notifyOff" />
                </Button>
              )}
            </>
          )}       
        </>
      )}
      {/* <div className={css.openOrderForm}>
        <div className={css.priceContainer}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.perUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div>
        </div>

        {isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="OrderPanel.closedListingButtonText" />
          </div>
        ) : (
          <Button
          rootClassName={css.orderButton}
          onClick={() => openOrderModal(isOwnListing, isClosed, history, location)}
          disabled={isOutOfStock}
        >
          {isOutOfStock ? (
            <FormattedMessage id="OrderPanel.ctaButtonMessageNoStock" />
          ) : (
            <FormattedMessage id="OrderPanel.ctaButtonMessage" />
          )}
        </Button>
        )}
      </div> */}
    </div>
  );
};

OrderPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.lineItemUnitType,
  timeSlots: null,
  fetchTimeSlotsError: null,
  lineItems: null,
  fetchLineItemsError: null,
};

OrderPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.lineItemUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  onFetchTransactionLineItems: func.isRequired,
  onContactUser: func,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withRouter, injectIntl)(OrderPanel);
