import { FormattedMessage } from 'react-intl';
import usStates from 'us-state-codes';
import {
  ALL_GENDER,
  CLOTHING,
  DISCOUNT,
  DRAFT,
  LINE_ITEM_SHIPPING_FEE,
  LINE_ITEM_UNITS,
  MANAGE_LISTING_DISCOUNT_PAGE,
  MANAGE_LISTING_DRAFT_PAGE,
  MANAGE_LISTING_OFFERS_PAGE,
  MANAGE_LISTING_POST_PAGE,
  MANAGE_LISTING_SOLD_PAGE,
  MEN_GENDER,
  OFFERS,
  PUBLISHED,
  SNEAKERS,
  SOLD,
  STREETWEAR,
  VINTAGE,
  WOMEN_GENDER,
} from './types';
import {
  TRANSITION_ACCEPTED_FROM_DISPUTED,
  TRANSITION_AUTO_COMPLETE,
  TRANSITION_AUTO_MARK_RECEIVED,
  TRANSITION_CANCEL_AFTER_PRINT_LABEL,
  TRANSITION_CANCEL_BY_CUSTOMER,
  TRANSITION_CANCEL_BY_PROVIDER,
  TRANSITION_CANCEL_FROM_DISPUTED,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_COUNTER_OFFER_ACCEPTED_BY_CUSTOMER,
  TRANSITION_DISPUTE,
  TRANSITION_ENQUIRE,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_REVIEW_PERIOD,
  TRANSITION_MARK_DELIVERED_OPERTOR,
  TRANSITION_MARK_RECEIVED,
  TRANSITION_OFFER_ACCEPTED_BY_PROVIDER,
  TRANSITION_REFUND,
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_REQUEST_PAYMENT_AFTER_COUNTER_OFFER_ACCEPTED_BY_CUSTOMER,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_REQUEST_PAYMENT_AFTER_OFFER_ACCEPTED_PROVIDER,
  TRANSITION_RETURN_SHIPPING_LABEL,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_SHIPPING_LABEL,
} from './transaction';
import { fetchUserStripeAccount } from './api';

export const isEmailValid = email => {
  // Basic email format validation regex
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return emailRegex.test(email);
};

export const getColorCode = colorKey => {
  // Define color codes for each key
  const colorCodes = {
    red: '#FF0000',
    pink: '#FFC0CB',
    orange: '#FFA500',
    yellow: '#FFFF00',
    beige: '#A9957B',
    green: '#008000',
    blue: '#0000FF',
    purple: '#800080',
    gold: '#D4AF37',
    silver: '#C0C0C0',
    black: '#000000',
    gray: '#808080',
    white: '#FFFFFF',
    cream: '#FFFDD0',
    brown: '#A52A2A',
    tan: '#D2B48C',
    camo: '#78866B',
    multiColor: '#0000FF, #FF0000, #FFFF00', // Example multi-color code
  };
  return colorCodes[colorKey] || 'Color code not found';
};

export const uploadAndConvertToWebP = (e, cl, folderName, id, sdk) => {
  const imagesUrl = [];
  const formData = new FormData();
  const fileType = e.file.type;
  const uploadURL = process.env.REACT_APP_CLOUDINARY_URL;

  // Append the image transformation for WebP format
  const transformation = `f_webp`;

  formData.append('file', e.file);
  formData.append('upload_preset', process.env.REACT_APP_CLOUDINARY_PRESET);
  formData.append('resource_type', fileType);
  formData.append('api_key', process.env.REACT_APP_CLOUDINARY_API_KEY);
  formData.append('folder', e.fileName === 'tag' ? 'Listings - Tags' : folderName);
  formData.append('manifest_transformation', transformation);

  return fetch(uploadURL, { method: 'POST', body: formData })
    .then(responses => responses.text())
    .then(data => {
      const res = JSON.parse(data);
      return sdk.ownListings.show({ id }).then(r => {
        const listingImagesUrl = r.data.data.attributes.publicData.listingImagesUrl || [];
        const webpUrl = cl.url(res.public_id, { format: 'webp' }); // Generate WebP URL
        imagesUrl.push(...listingImagesUrl, {
          url: webpUrl,
          type: 'webp',
          fileName: e.fileName ? e.fileName : 'listing',
        });
        return sdk.ownListings.update({
          id,
          publicData: {
            listingImagesUrl: imagesUrl,
          },
        });
      });
    })
    .catch(error => {
      console.error('Error uploading image:', error);
    });
};

export const getListings = (history, pageListings) => {
  const pathnameParts = history.location.pathname.split('/');
  const storeId = pathnameParts[2];
  const genderFilter = pathnameParts[3];

  if (storeId) {
    if (genderFilter === ALL_GENDER) {
      return pageListings.filter(e => e?.attributes?.publicData?.listingStore === storeId);
    } else if (genderFilter === MEN_GENDER || genderFilter === WOMEN_GENDER) {
      return pageListings.filter(
        e =>
          e?.attributes?.publicData?.listingStore === storeId &&
          e?.attributes?.publicData?.gender === genderFilter
      );
    }
  }
  return pageListings;
};

const downloadFile = (fileUrl, fileName) => {
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = fileName;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadFiles = (url, name) => {
  const pdfFileUrl = url;
  const pdfFileName = name;
  return downloadFile(pdfFileUrl, pdfFileName);
};

export const getCategoryItems = (
  selectedCategory,
  vintage = [],
  streetwear = [],
  sneakers = [],
  clothing = []
) => {
  switch (selectedCategory) {
    case VINTAGE:
      return vintage.length > 0 ? vintage : [];
    case STREETWEAR:
      return streetwear.length > 0 ? streetwear : [];
    case SNEAKERS:
      return sneakers.length > 0 ? sneakers : [];
    case CLOTHING:
      return clothing.length > 0 ? clothing : [];
    default:
      return [];
  }
};

export const getOptions = (listings, type) => {
  const options =
    listings &&
    Array.isArray(listings) &&
    listings?.map(item => item?.attributes?.publicData[type] && item?.attributes?.publicData[type]);
  return options;
};

export const getOptionsByKey = (listings, type) => {
  const options = getOptions(listings, type);
  const filterOptions =
    options &&
    Array.isArray(options) &&
    [...new Set(options)]
      .map(item => {
        return { key: item, label: item };
      })
      .filter(i => i?.key !== null && typeof i?.key !== 'undefined');
  return filterOptions;
};

export const getOptionsByName = (name, options) => {
  switch (name) {
    case 'brand':
      return options.brandOptions;
    default:
      return false;
  }
};

export const getUserFavData = (currentUser = {}) => {
  const { attributes: { profile: { protectedData: { favouriteItems = [] } = {} } = {} } = {} } =
    currentUser || {};

  return favouriteItems;
};

export const getUserBagData = (currentUser = {}) => {
  const {
    attributes: {
      profile: {
        protectedData: { bookmarks = [] } = {},
      } = {},
    } = {},
  } = currentUser || {};

  // If there are no bookmarks in currentUser, try getting them from localStorage
  if (!bookmarks.length && typeof window !== 'undefined') {
    const localBookmarks =
      JSON.parse(window.localStorage.getItem('localBookmarks')) || [];
    return localBookmarks;
  }

  return bookmarks;
};


export const getUserSaveLaterData = (currentUser = {}) => {
  const { attributes: { profile: { protectedData: { saveLaterItems = [] } = {} } = {} } = {} } =
    currentUser || {};

  return saveLaterItems;
};

export const getTotalPriceArray = (params) => {
  const { isAuthenticated, cl, bookmarks, localBookMark } = params || {};
  const matchedListingsArr = isAuthenticated
    ? (bookmarks &&
      bookmarks
        .map(item => {
          const index = cl && cl.findIndex(c => c.id.uuid === item.id || item);
          if (index > -1) {
            return cl;
          }
          return null; // Return null if no match is found
        })
        .filter(item => item !== null)) // Remove null values from the array
    : (localBookMark &&
      localBookMark
        .map(item => {
          const index = cl && cl.findIndex(c => c.id.uuid === item);
          if (index > -1) {
            return cl;
          }
          return null; // Return null if no match is found
        })
        .filter(item => item !== null)); // Remove null values from the array
  return matchedListingsArr;
};

export const filterFavListing = (favListings, currentUser = {}) => {
  const userFavItems = currentUser?.attributes?.profile?.protectedData?.favouriteItems || [];
  return favListings?.filter(listing => userFavItems.includes(listing.id.uuid));
};

export const localBagData = () => {
  const isLocalStorageAvailable =
    typeof window !== 'undefined' &&
    window.localStorage &&
    typeof window.localStorage.getItem('localBookmarks') === 'string';

  const localBookmarks = isLocalStorageAvailable
    ? JSON.parse(window.localStorage.getItem('localBookmarks'))
    : [];
  return localBookmarks;
};

export const getPageRoute = (state, isProfile) => {
  switch (state) {
    case PUBLISHED:
      return MANAGE_LISTING_POST_PAGE;
    case DRAFT:
      return MANAGE_LISTING_DRAFT_PAGE;
    case DISCOUNT:
      return MANAGE_LISTING_DISCOUNT_PAGE;
    case SOLD:
      return MANAGE_LISTING_SOLD_PAGE;
    case OFFERS:
      return MANAGE_LISTING_OFFERS_PAGE;
    default:
      return isProfile ? 'ProfilePage' : 'SearchPage';
  }
};

export const getRoute = (params, isProfile) => {
  const pageRoute =
    params.state === PUBLISHED
      ? MANAGE_LISTING_POST_PAGE
      : params.state === DRAFT
        ? MANAGE_LISTING_DRAFT_PAGE
        : params.state === DISCOUNT
          ? MANAGE_LISTING_DISCOUNT_PAGE
          : params.state === SOLD
            ? MANAGE_LISTING_SOLD_PAGE
            : isProfile
              ? 'ProfilePage'
              : 'SearchPage';
  return pageRoute;
};

export const getFormatText = params => {
  const stateMessages = {
    [PUBLISHED]: <FormattedMessage id="ManageListingsPage.myStore" />,
    [DRAFT]: <FormattedMessage id="ManageListingsPage.draftStore" />,
    [SOLD]: <FormattedMessage id="ManageListingsPage.soldStore" />,
    [DISCOUNT]: <FormattedMessage id="ManageListingsPage.discountStore" />,
    default: <FormattedMessage id="ManageListingsPage.offerStore" />,
  };

  return stateMessages[params.state] || stateMessages.default;
};

export const readTimeHandle = description => {
  const wordsPerMinute = 200; // Average reading speed in words per minute

  const wordCount = description.split(' ').length;
  const timeInMinutes = wordCount / wordsPerMinute;

  // Round up to the nearest minute
  return Math.ceil(timeInMinutes);
};

export const getUserDetails = user => {
  const fullName = user?.attributes?.profile?.firstName
    ? user?.attributes?.profile?.firstName + ' ' + user?.attributes?.profile?.lastName
    : user?.attributes?.profile?.displayName;
  let profileImage = null;
  if (!!user?.id && user?.profileImage?.attributes?.variants) {
    if (user?.profileImage?.attributes?.variants?.default) {
      profileImage = user?.profileImage?.attributes?.variants?.default?.url;
    } else {
      profileImage = user?.profileImage?.attributes?.variants['square-small2x']?.url;
    }
  } else {
    profileImage = user?.attributes?.profile?.publicData?.picture;
  }
  const email =
    !!user?.id && user?.attributes?.email
      ? user?.attributes?.email
      : user?.attributes?.profile?.publicData?.email;
  const id = user?.id && user?.id?.uuid;
  const userName = user?.id && user?.attributes?.profile?.publicData?.userName;
  const firstName =
    user?.attributes?.profile?.displayName && user?.attributes?.profile?.displayName.split(' ')[0];
  return {
    fullName,
    profileImage,
    userName,
    email,
    id,
    firstName,
  };
};
export const getUserShortName = fullName => {
  if (!fullName) return;

  if (!!fullName)
    return fullName
      .match(/(\b\S)?/g)
      .join('')
      .toUpperCase();
};

export const getLabel = (category, key) => {
  const label = category.find(c => c.key === key);
  return label ? label.label : key;
};

export const getLastTransitions = (lastTransition, isTransition) => {
  let transition;

  if (isTransition) {
    if (lastTransition === TRANSITION_ENQUIRE) {
      transition = TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY;
    } else if (lastTransition === TRANSITION_OFFER_ACCEPTED_BY_PROVIDER) {
      transition = TRANSITION_REQUEST_PAYMENT_AFTER_OFFER_ACCEPTED_PROVIDER;
    } else if (lastTransition === TRANSITION_COUNTER_OFFER_ACCEPTED_BY_CUSTOMER) {
      transition = TRANSITION_REQUEST_PAYMENT_AFTER_COUNTER_OFFER_ACCEPTED_BY_CUSTOMER;
    } else {
      transition = TRANSITION_REQUEST_PAYMENT;
    }
  } else {
    transition = TRANSITION_REQUEST_PAYMENT; // Default value if isTransition is false
  }

  return transition; // Add this line to return the computed value
};

export const getBuyerState = (buyerDefaultAddress, recipientState) => {
  const inputState = buyerDefaultAddress.userShippingState || recipientState;
  if (usStates.getStateCodeByStateName(inputState)) {
    return usStates.getStateCodeByStateName(inputState);
  } else {
    return usStates.getStateCodeByStateName(usStates.getStateNameByStateCode(inputState));
  }
};

export const getSellerState = sellerDefaultAddress => {
  const inputState = sellerDefaultAddress.sellerState || sellerDefaultAddress.userShippingState;
  if (usStates.getStateCodeByStateName(inputState)) {
    return usStates.getStateCodeByStateName(inputState);
  } else {
    return usStates.getStateCodeByStateName(usStates.getStateNameByStateCode(inputState));
  }
};

export const filterRateOptions = data => {
  const rateOptions = data.filter(e => e.service_code === 'usps_ground_advantage');
  return rateOptions.length > 0 ? rateOptions : data;
};

export const extractShippingData = filteredRates => {
  const filteredRateData = filteredRates.find(e => e);
  return {
    shipping_price: filteredRateData?.shipping_amount?.amount,
    delivery_days: filteredRateData?.delivery_days,
    service_type: filteredRateData?.service_type,
    trackable: filteredRateData?.trackable,
    service_code: filteredRateData?.service_code,
  };
};

export const orderText = (lineItem) => {
  if (lineItem.code === LINE_ITEM_UNITS) {
    return "Order";
  } else if (lineItem.code === LINE_ITEM_SHIPPING_FEE) {
    return "Shipping Fee";
  } else {
    return "Marketplace Fee";
  }
}

export const fetchTransitions = () => {
  const transitions = [
    TRANSITION_MARK_DELIVERED_OPERTOR,
    TRANSITION_MARK_RECEIVED,
    TRANSITION_AUTO_MARK_RECEIVED,
    TRANSITION_AUTO_COMPLETE,
    TRANSITION_REVIEW_1_BY_PROVIDER,
    TRANSITION_REVIEW_2_BY_PROVIDER,
    TRANSITION_REVIEW_1_BY_CUSTOMER,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
    TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
    TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
    TRANSITION_EXPIRE_REVIEW_PERIOD,
    TRANSITION_REQUEST_PAYMENT,
    TRANSITION_CONFIRM_PAYMENT
  ];
  return transitions;
};

export const fetchOrderTransitions = () => {
  const transitions = [
    TRANSITION_REQUEST_PAYMENT,
    TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
    TRANSITION_REQUEST_PAYMENT_AFTER_OFFER_ACCEPTED_PROVIDER,
    TRANSITION_REQUEST_PAYMENT_AFTER_COUNTER_OFFER_ACCEPTED_BY_CUSTOMER,
    TRANSITION_CONFIRM_PAYMENT,
    TRANSITION_MARK_DELIVERED_OPERTOR,
    TRANSITION_MARK_RECEIVED,
    TRANSITION_AUTO_MARK_RECEIVED,
    TRANSITION_AUTO_COMPLETE,
    TRANSITION_REVIEW_1_BY_PROVIDER,
    TRANSITION_REVIEW_2_BY_PROVIDER,
    TRANSITION_REVIEW_1_BY_CUSTOMER,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
    TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
    TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
    TRANSITION_EXPIRE_REVIEW_PERIOD,
    TRANSITION_SHIPPING_LABEL,
    TRANSITION_DISPUTE,
    TRANSITION_CANCEL_BY_CUSTOMER,
    TRANSITION_CANCEL_BY_PROVIDER,
    TRANSITION_ACCEPTED_FROM_DISPUTED,
    TRANSITION_RETURN_SHIPPING_LABEL,
    TRANSITION_CANCEL_AFTER_PRINT_LABEL,
    TRANSITION_CANCEL_FROM_DISPUTED,
    TRANSITION_REFUND
  ];
  return transitions;
};

export const getCategoryArray = (category, gender) => {
  switch (category.label) {
    case 'Bottoms':
      return (gender === MEN_GENDER)
        ? ['pants,jeans,shorts']
        : (gender === WOMEN_GENDER || gender === ALL_GENDER || !gender)
          ? ['pants,jeans,shorts,skirts']
          : null;

    case 'Other':
      return (gender === MEN_GENDER)
        ? ['costumes']
        : (gender === WOMEN_GENDER || gender === ALL_GENDER || !gender)
          ? ['costumes,intimates-sleepwear,suits-separates']
          : null;
    case 'Jewelry':
      return (gender === ALL_GENDER || !gender)
        ? ['jewelry,accessories'] :
        (gender === MEN_GENDER || !gender)
          ? ['accessories'] :
          category.key;
    case 'All':
      return null;

    case 'Tops':
      return ['tops,sweaters']
    default:
      return category.key;
  }
};

export const fetchAuthorData = async (user) => {
  try {
    const userId = user?.id?.uuid;
    const result = await fetchUserStripeAccount({ userId });
    return result.data.data;
  } catch (error) {
    console.log(error);
  }
};

export const truncateNumber = (originalNumber, length) => {
  if (originalNumber.length <= length) {
    return originalNumber;
  } else {
    const firstTenDigits = originalNumber.slice(0, length);
    return `${firstTenDigits}...`;
    // const lastFiveDigits = originalNumber.slice(-5);
    // return `...${lastFiveDigits}`;
  }
};


export const isEmailVerified = (user) => {
  // Input validation 
  if (!user || !user.id || typeof user.attributes.emailVerified !== 'boolean') {
    return false; 
  }
  // Concise and explicit condition
  return user.id && user.attributes.emailVerified;
};
