import { ensureTransaction } from './data';

/**
 * Transitions
 *
 * These strings must sync with values defined in Flex API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

// When a customer makes an order for a listing, a transaction is
// created with the initial request-payment transition.
// At this transition a PaymentIntent is created by Marketplace API.
// After this transition, the actual payment must be made on client-side directly to Stripe.
export const TRANSITION_REQUEST_PAYMENT = 'transition/request-payment';

// A customer can also initiate a transaction with an enquiry, and
// then transition that with a request.
export const TRANSITION_ENQUIRE = 'transition/enquire';
export const TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY = 'transition/request-payment-after-enquiry';

// Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
// Therefore we need to make another transition to Marketplace API,
// to tell that the payment is confirmed.
export const TRANSITION_CONFIRM_PAYMENT = 'transition/confirm-payment';

// If the payment is not confirmed in the time limit set in transaction process (by default 15min)
// the transaction will expire automatically.
export const TRANSITION_EXPIRE_PAYMENT = 'transition/expire-payment';

// Provider can mark the product shipped/delivered
export const TRANSITION_MARK_DELIVERED = 'transition/mark-delivered';
export const TRANSITION_MARK_DELIVERED_OPERTOR = 'transition/mark-delivered-operator';

// Customer can mark the product received (e.g. picked up from provider)
export const TRANSITION_MARK_RECEIVED_FROM_PURCHASED = 'transition/mark-received-from-purchased';
export const TRANSITION_MARK_RECEIVED_FROM_PURCHASED_OPERTOR =
  'transition/mark-received-from-purchased-operator';

// Automatic cancellation happens if none marks the delivery happened
export const TRANSITION_AUTO_CANCEL = 'transition/auto-cancel';

export const TRANSITION_EXPIRE_ORDER = 'transition/expire-order';

// Operator can cancel the purchase before product has been marked as delivered / received
export const TRANSITION_CANCEL = 'transition/cancel';
export const TRANSITION_CANCEL_BY_CUSTOMER = 'transition/cancel-order-by-customer';
export const TRANSITION_CANCEL_BY_PROVIDER = 'transition/cancel-order-by-provider';

// If provider has marked the product delivered (e.g. shipped),
// customer can then mark the product received
export const TRANSITION_MARK_RECEIVED = 'transition/mark-received';
export const TRANSITION_MARK_RECEIVED_OPERTOR = 'transition/mark-received-operator';

// If customer doesn't mark the product received manually, it can happen automatically
export const TRANSITION_AUTO_MARK_RECEIVED = 'transition/auto-mark-received';

// When provider has marked the product delivered, customer can dispute the transaction
export const TRANSITION_DISPUTE = 'transition/dispute';

export const TRANSITION_REFUND = 'transition/refund';

// If nothing is done to disputed transaction it ends up to Canceled state
export const TRANSITION_AUTO_CANCEL_FROM_DISPUTED = 'transition/auto-cancel-from-disputed';

// Operator can cancel disputed transaction manually
export const TRANSITION_CANCEL_FROM_DISPUTED = 'transition/resolved-dispute-without-refund';

// Operator can accept disputed transaction manually
export const TRANSITION_ACCEPTED_FROM_DISPUTED = 'transition/accept-dispute';

export const TRANSITION_RETURN_SHIPPING_LABEL ='transition/resolved-dispute-with-refund';

// Operator can mark the disputed transaction as received
export const TRANSITION_MARK_RECEIVED_FROM_DISPUTED = 'transition/mark-received-from-disputed';

// System moves transaction automatically from received state to complete state
// This makes it possible to to add notifications to that single transition.
export const TRANSITION_AUTO_COMPLETE = 'transition/auto-complete';

// Reviews are given through transaction transitions. Review 1 can be
// by provider or customer, and review 2 will be the other party of
// the transaction.
export const TRANSITION_REVIEW_1_BY_PROVIDER = 'transition/review-1-by-provider';
export const TRANSITION_REVIEW_2_BY_PROVIDER = 'transition/review-2-by-provider';
export const TRANSITION_REVIEW_1_BY_CUSTOMER = 'transition/review-1-by-customer';
export const TRANSITION_REVIEW_2_BY_CUSTOMER = 'transition/review-2-by-customer';
export const TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD = 'transition/expire-customer-review-period';
export const TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD = 'transition/expire-provider-review-period';
export const TRANSITION_EXPIRE_REVIEW_PERIOD = 'transition/expire-review-period';

// make ann offer related transitions
export const TRANSITION_MAKE_AN_OFFER_BY_CUSTOMER = 'transition/make-an-offer-by-customer';
export const TRANSITION_OFFER_ACCEPTED_BY_PROVIDER = 'transition/offer-accept-by-provider';
export const TRANSITION_OFFER_DECLINED_BY_PROVIDER = 'transition/offer-decline-by-provider';
export const TRANSITION_OFFER_COUNTERED_BY_PROVIDER = 'transition/offer-counter-by-provider';
export const TRANSITION_OFFER_AUTO_EXPIRE = 'transition/expire-offer';
export const TRANSITION_REQUEST_PAYMENT_AFTER_OFFER_ACCEPTED_PROVIDER =
  'transition/request-payment-after-offer-accept-by-provider';
export const TRANSITION_COUNTER_OFFER_ACCEPTED_BY_CUSTOMER =
  'transition/counter-offer-accept-by-customer';
export const TRANSITION_COUNTER_OFFER_DECLINED_BY_CUSTOMER =
  'transition/counter-offer-decline-by-customer';
export const TRANSITION_REQUEST_PAYMENT_AFTER_COUNTER_OFFER_ACCEPTED_BY_CUSTOMER =
  'transition/request-payment-after-counter-offer-acceptd';
export const TRANSITION_COUNTER_OFFER_COUNTERED_BY_CUSTOMER =
  'transition/counter-offer-countered-by-customer';
export const TRANSITION_COUNTER_OFFER_AUTO_EXPIRE = 'transition/expire-counter-offer';

export const TRANSITION_SHIPPING_LABEL = 'transition/shipping-label';

export const TRANSITION_CANCEL_AFTER_PRINT_LABEL= 'transition/cancel-order-after-print-label';

/**
 * Actors
 *
 * There are 4 different actors that might initiate transitions:
 */

// Roles of actors that perform transaction transitions
export const TX_TRANSITION_ACTOR_CUSTOMER = 'customer';
export const TX_TRANSITION_ACTOR_PROVIDER = 'provider';
export const TX_TRANSITION_ACTOR_SYSTEM = 'system';
export const TX_TRANSITION_ACTOR_OPERATOR = 'operator';

export const TX_TRANSITION_ACTORS = [
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  TX_TRANSITION_ACTOR_SYSTEM,
  TX_TRANSITION_ACTOR_OPERATOR,
];

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
const STATE_INITIAL = 'initial';
const STATE_ENQUIRY = 'enquiry';
const STATE_PENDING_PAYMENT = 'pending-payment';
const STATE_PAYMENT_EXPIRED = 'payment-expired';
const STATE_PURCHASED = 'purchased';
const STATE_DELIVERED = 'delivered';
const STATE_RECEIVED = 'received';
const STATE_DISPUTED = 'disputed';
const STATE_CANCELED = 'canceled';
const STATE_ACCEPTED_DISPUTE = 'dispute-accepted';
const STATE_COMPLETED = 'completed';
const STATE_REVIEWED = 'reviewed';
const STATE_REVIEWED_BY_CUSTOMER = 'reviewed-by-customer';
const STATE_REVIEWED_BY_PROVIDER = 'reviewed-by-provider';
const STATE_MAKE_AN_OFFER = 'make-an-offer';
const STATE_OFFER_EXPIRE = 'offer-expire';
const STATE_OFFER_ACCEPTED = 'offer-accepted';
const STATE_OFFER_DECLINED = 'offer-declined';
const STATE_OFFER_COUNTERED = 'offer-countered';
const STATE_COUNTER_OFFER_ACCEPTED = 'counter-offer-accepted';
const STATE_COUNTER_OFFER_DECLINED = 'counter-offer-declined';
const STATE_COUNTER_OFFER_EXPIRE = 'counter-offer-expire';
const STATE_RETURN_SHIPPING = 'return-shipping';
const STATE_REFUNDED = 'refunded';
const STATE_CANCEL_DISPUTE ='dispute-canceled';
const STATE_PRINT_LABEL = 'print-label';

/**
 * Description of transaction process
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
const stateDescription = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'flex-product-default-process/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: STATE_INITIAL,

  // States
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_ENQUIRE]: STATE_ENQUIRY,
        [TRANSITION_REQUEST_PAYMENT]: STATE_PENDING_PAYMENT,
        [TRANSITION_MAKE_AN_OFFER_BY_CUSTOMER]: STATE_MAKE_AN_OFFER,
      },
    },
    [STATE_ENQUIRY]: {
      on: {
        [TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY]: STATE_PENDING_PAYMENT,
      },
    },
    [STATE_MAKE_AN_OFFER]: {
      on: {
        [TRANSITION_OFFER_ACCEPTED_BY_PROVIDER]: STATE_OFFER_ACCEPTED,
        [TRANSITION_OFFER_DECLINED_BY_PROVIDER]: STATE_OFFER_DECLINED,
        [TRANSITION_OFFER_COUNTERED_BY_PROVIDER]: STATE_OFFER_COUNTERED,
        [TRANSITION_OFFER_AUTO_EXPIRE]: STATE_OFFER_EXPIRE,
      },
    },
    [STATE_OFFER_ACCEPTED]: {
      on: {
        [TRANSITION_REQUEST_PAYMENT_AFTER_OFFER_ACCEPTED_PROVIDER]: STATE_PENDING_PAYMENT,
      },
    },
    [STATE_OFFER_DECLINED]: {},
    [STATE_OFFER_EXPIRE]: {},
    [STATE_OFFER_COUNTERED]: {
      on: {
        [TRANSITION_COUNTER_OFFER_ACCEPTED_BY_CUSTOMER]: STATE_COUNTER_OFFER_ACCEPTED,
        [TRANSITION_COUNTER_OFFER_DECLINED_BY_CUSTOMER]: STATE_COUNTER_OFFER_DECLINED,
        [TRANSITION_COUNTER_OFFER_COUNTERED_BY_CUSTOMER]: STATE_MAKE_AN_OFFER,
        [TRANSITION_COUNTER_OFFER_AUTO_EXPIRE]: STATE_COUNTER_OFFER_EXPIRE,
      },
    },
    [STATE_COUNTER_OFFER_EXPIRE]: {},
    [STATE_COUNTER_OFFER_ACCEPTED]: {
      on: {
        [TRANSITION_REQUEST_PAYMENT_AFTER_COUNTER_OFFER_ACCEPTED_BY_CUSTOMER]: STATE_PENDING_PAYMENT,
      },
    },
    [STATE_COUNTER_OFFER_DECLINED]: {},
    [STATE_PENDING_PAYMENT]: {
      on: {
        [TRANSITION_EXPIRE_PAYMENT]: STATE_PAYMENT_EXPIRED,
        [TRANSITION_CONFIRM_PAYMENT]: STATE_PURCHASED,
      },
    },

    [STATE_PAYMENT_EXPIRED]: {},
    [STATE_PURCHASED]: {
      on: {
        [TRANSITION_EXPIRE_ORDER]:STATE_CANCELED,
        [TRANSITION_MARK_RECEIVED_FROM_PURCHASED]: STATE_RECEIVED,
        [TRANSITION_MARK_RECEIVED_FROM_PURCHASED_OPERTOR]: STATE_RECEIVED,
        [TRANSITION_AUTO_CANCEL]: STATE_CANCELED,
        [TRANSITION_CANCEL]: STATE_CANCELED,
        [TRANSITION_CANCEL_BY_CUSTOMER]: STATE_CANCELED,
        [TRANSITION_CANCEL_BY_PROVIDER]: STATE_CANCELED,
        [TRANSITION_SHIPPING_LABEL]: STATE_PRINT_LABEL,
      },
    },

    [STATE_PRINT_LABEL]:{
      on:{
        [TRANSITION_MARK_DELIVERED]: STATE_DELIVERED,
        [TRANSITION_MARK_DELIVERED_OPERTOR]: STATE_DELIVERED,
        [TRANSITION_CANCEL_AFTER_PRINT_LABEL]:STATE_CANCELED
      }
    },

    [STATE_CANCELED]: {},
    [STATE_DELIVERED]: {
      on: {
        [TRANSITION_MARK_RECEIVED]: STATE_RECEIVED,
        [TRANSITION_MARK_RECEIVED_OPERTOR]: STATE_RECEIVED,
        [TRANSITION_AUTO_MARK_RECEIVED]: STATE_RECEIVED,
        [TRANSITION_DISPUTE]: STATE_DISPUTED,
      },
    },

    [STATE_DISPUTED]: {
      on: {
        [TRANSITION_AUTO_CANCEL_FROM_DISPUTED]: STATE_CANCELED,
        [TRANSITION_ACCEPTED_FROM_DISPUTED]:STATE_ACCEPTED_DISPUTE,
        [TRANSITION_MARK_RECEIVED_FROM_DISPUTED]: STATE_RECEIVED,
      },
    },
    [STATE_CANCEL_DISPUTE]:{},
    
    [STATE_ACCEPTED_DISPUTE]:{
      on:{
        [TRANSITION_CANCEL_FROM_DISPUTED]: STATE_CANCEL_DISPUTE,
        [TRANSITION_RETURN_SHIPPING_LABEL]:STATE_RETURN_SHIPPING,
      }
    },
    [STATE_RETURN_SHIPPING]:{
      on:{
        [TRANSITION_REFUND]:STATE_REFUNDED,
      }
    },
    [STATE_REFUNDED]:{},
    [STATE_RECEIVED]: {
      on: {
        [TRANSITION_AUTO_COMPLETE]: STATE_COMPLETED,
      },
    },

    [STATE_COMPLETED]: {
      on: {
        [TRANSITION_EXPIRE_REVIEW_PERIOD]: STATE_REVIEWED,
        [TRANSITION_REVIEW_1_BY_CUSTOMER]: STATE_REVIEWED_BY_CUSTOMER,
        [TRANSITION_REVIEW_1_BY_PROVIDER]: STATE_REVIEWED_BY_PROVIDER,
      },
    },

    [STATE_REVIEWED_BY_CUSTOMER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_PROVIDER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED_BY_PROVIDER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_CUSTOMER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED]: { type: 'final' },
  },
};

// Note: currently we assume that state description doesn't contain nested states.
const statesFromStateDescription = description => description.states || {};

// Get all the transitions from states object in an array
const getTransitions = states => {
  const stateNames = Object.keys(states);

  const transitionsReducer = (transitionArray, name) => {
    const stateTransitions = states[name] && states[name].on;
    const transitionKeys = stateTransitions ? Object.keys(stateTransitions) : [];
    return [
      ...transitionArray,
      ...transitionKeys.map(key => ({ key, value: stateTransitions[key] })),
    ];
  };

  return stateNames.reduce(transitionsReducer, []);
};

// This is a list of all the transitions that this app should be able to handle.
export const TRANSITIONS = getTransitions(statesFromStateDescription(stateDescription)).map(
  t => t.key
);

// This function returns a function that has given stateDesc in scope chain.
const getTransitionsToStateFn = stateDesc => state =>
  getTransitions(statesFromStateDescription(stateDesc))
    .filter(t => t.value === state)
    .map(t => t.key);

// Get all the transitions that lead to specified state.
const getTransitionsToState = getTransitionsToStateFn(stateDescription);

// This is needed to fetch transactions that need response from provider.
// I.e. transactions which provider needs to accept or decline
export const transitionsToRequested = getTransitionsToState(STATE_PURCHASED);

/**
 * Helper functions to figure out if transaction is in a specific state.
 * State is based on lastTransition given by transaction object and state description.
 */

const txLastTransition = tx => ensureTransaction(tx).attributes.lastTransition;

export const txIsEnquired = tx =>
  getTransitionsToState(STATE_ENQUIRY).includes(txLastTransition(tx));

export const txIsPaymentPending = tx =>
  getTransitionsToState(STATE_PENDING_PAYMENT).includes(txLastTransition(tx));

export const txIsMakeAnOffer = tx =>
  getTransitionsToState(STATE_MAKE_AN_OFFER).includes(txLastTransition(tx));

export const txIsOfferAccepted = tx =>
  getTransitionsToState(STATE_OFFER_ACCEPTED).includes(txLastTransition(tx));

export const txIsOfferDeclined = tx =>
  getTransitionsToState(STATE_OFFER_DECLINED).includes(txLastTransition(tx));

export const txIsOfferExpired = tx =>
  getTransitionsToState(STATE_OFFER_EXPIRE).includes(txLastTransition(tx));

export const txIsCounterOfferExpired = tx =>
  getTransitionsToState(STATE_COUNTER_OFFER_EXPIRE).includes(txLastTransition(tx));

export const txIsCounterOfferDeclined = tx =>
  getTransitionsToState(STATE_COUNTER_OFFER_DECLINED).includes(txLastTransition(tx));

export const txIsOfferCountered = tx =>
  getTransitionsToState(STATE_OFFER_COUNTERED).includes(txLastTransition(tx));

export const txIsCounterOfferAccepted = tx =>
  getTransitionsToState(STATE_COUNTER_OFFER_ACCEPTED).includes(txLastTransition(tx));

export const txIsPaymentExpired = tx =>
  getTransitionsToState(STATE_PAYMENT_EXPIRED).includes(txLastTransition(tx));

export const txIsPurchased = tx =>
  getTransitionsToState(STATE_PURCHASED).includes(txLastTransition(tx));

  export const txIsPrintLabel = tx =>
  getTransitionsToState(STATE_PRINT_LABEL).includes(txLastTransition(tx));

export const txIsCanceled = tx =>
  getTransitionsToState(STATE_CANCELED).includes(txLastTransition(tx));

export const txIsDelivered = tx =>
  getTransitionsToState(STATE_DELIVERED).includes(txLastTransition(tx));

export const txIsDisputed = tx =>
  getTransitionsToState(STATE_DISPUTED).includes(txLastTransition(tx));

export const txIsDisputeAccepted = tx =>
  getTransitionsToState(STATE_ACCEPTED_DISPUTE).includes(txLastTransition(tx));

export const txIsDisputeReturnLabel = tx =>
  getTransitionsToState(STATE_RETURN_SHIPPING).includes(txLastTransition(tx));

export const txIsDisputeRefunded = tx =>
  getTransitionsToState(STATE_REFUNDED).includes(txLastTransition(tx));

export const txIsDisputeCancel = tx =>
  getTransitionsToState(STATE_CANCEL_DISPUTE).includes(txLastTransition(tx));

export const txIsReceived = tx =>
  getTransitionsToState(STATE_RECEIVED).includes(txLastTransition(tx));

export const txIsCompleted = tx =>
  getTransitionsToState(STATE_COMPLETED).includes(txLastTransition(tx));

export const txIsReviewedByCustomer = tx =>
  getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx));

export const txIsReviewedByProvider = tx =>
  getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx));

const firstReviewTransitions = [
  ...getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER),
  ...getTransitionsToState(STATE_REVIEWED_BY_PROVIDER),
];
export const txIsInFirstReview = tx => firstReviewTransitions.includes(txLastTransition(tx));

export const txIsInFirstReviewBy = (tx, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx))
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx));

export const txIsReviewed = tx =>
  getTransitionsToState(STATE_REVIEWED).includes(txLastTransition(tx));

/**
 * Helper functions to figure out if transaction has passed a given state.
 * This is based on transitions history given by transaction object.
 */

const txTransitions = tx => ensureTransaction(tx).attributes.transitions || [];
const hasPassedTransition = (transitionName, tx) =>
  !!txTransitions(tx).find(t => t.transition === transitionName);

const hasPassedStateFn = state => tx =>
  getTransitionsToState(state).filter(t => hasPassedTransition(t, tx)).length > 0;

// Helper function to check if the transaction has passed a certain state
export const txHasPassedPaymentPending = hasPassedStateFn(STATE_PENDING_PAYMENT);
export const txHasBeenReceived = hasPassedStateFn(STATE_RECEIVED);

/**
 * Other transaction related utility functions
 */

export const transitionIsReviewed = transition =>
  getTransitionsToState(STATE_REVIEWED).includes(transition);

export const transitionIsFirstReviewedBy = (transition, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(transition)
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(transition);

export const getReview1Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_1_BY_CUSTOMER : TRANSITION_REVIEW_1_BY_PROVIDER;

export const getReview2Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_2_BY_CUSTOMER : TRANSITION_REVIEW_2_BY_PROVIDER;

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    TRANSITION_CONFIRM_PAYMENT,
    TRANSITION_AUTO_CANCEL,
    TRANSITION_CANCEL,
    TRANSITION_MARK_RECEIVED_FROM_PURCHASED,
    TRANSITION_MARK_DELIVERED,
    TRANSITION_DISPUTE,
    TRANSITION_REFUND,
    TRANSITION_MARK_RECEIVED,
    TRANSITION_AUTO_MARK_RECEIVED,
    TRANSITION_MARK_RECEIVED_FROM_DISPUTED,
    TRANSITION_AUTO_CANCEL_FROM_DISPUTED,
    TRANSITION_CANCEL_FROM_DISPUTED,
    TRANSITION_ACCEPTED_FROM_DISPUTED,
    TRANSITION_RETURN_SHIPPING_LABEL,
    TRANSITION_REVIEW_1_BY_CUSTOMER,
    TRANSITION_REVIEW_1_BY_PROVIDER,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
    TRANSITION_REVIEW_2_BY_PROVIDER,
    TRANSITION_OFFER_ACCEPTED_BY_PROVIDER,
    TRANSITION_OFFER_DECLINED_BY_PROVIDER,
    TRANSITION_OFFER_COUNTERED_BY_PROVIDER,
    TRANSITION_REQUEST_PAYMENT_AFTER_OFFER_ACCEPTED_PROVIDER,
    TRANSITION_COUNTER_OFFER_DECLINED_BY_CUSTOMER,
    TRANSITION_COUNTER_OFFER_ACCEPTED_BY_CUSTOMER,
    TRANSITION_REQUEST_PAYMENT_AFTER_COUNTER_OFFER_ACCEPTED_BY_CUSTOMER,
    TRANSITION_MAKE_AN_OFFER_BY_CUSTOMER,
    TRANSITION_OFFER_AUTO_EXPIRE,
    TRANSITION_COUNTER_OFFER_AUTO_EXPIRE,
    TRANSITION_SHIPPING_LABEL,
    TRANSITION_COUNTER_OFFER_COUNTERED_BY_CUSTOMER,
    TRANSITION_MARK_DELIVERED_OPERTOR,
    TRANSITION_MARK_RECEIVED_OPERTOR,
    TRANSITION_MARK_RECEIVED_FROM_PURCHASED_OPERTOR,
    TRANSITION_CANCEL_BY_CUSTOMER,
    TRANSITION_CANCEL_AFTER_PRINT_LABEL,
    TRANSITION_CANCEL_BY_PROVIDER,
  ].includes(transition);
};

export const isCustomerReview = transition => {
  return [TRANSITION_REVIEW_1_BY_CUSTOMER, TRANSITION_REVIEW_2_BY_CUSTOMER].includes(transition);
};

export const isProviderReview = transition => {
  return [TRANSITION_REVIEW_1_BY_PROVIDER, TRANSITION_REVIEW_2_BY_PROVIDER].includes(transition);
};

export const getUserTxRole = (currentUserId, transaction) => {
  const tx = ensureTransaction(transaction);
  const customer = tx.customer;
  if (currentUserId && currentUserId.uuid && tx.id && customer.id) {
    // user can be either customer or provider
    return currentUserId.uuid === customer.id.uuid
      ? TX_TRANSITION_ACTOR_CUSTOMER
      : TX_TRANSITION_ACTOR_PROVIDER;
  } else {
    throw new Error(`Parameters for "userIsCustomer" function were wrong.
      currentUserId: ${currentUserId}, transaction: ${transaction}`);
  }
};

export const txRoleIsProvider = userRole => userRole === TX_TRANSITION_ACTOR_PROVIDER;
export const txRoleIsCustomer = userRole => userRole === TX_TRANSITION_ACTOR_CUSTOMER;

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  return [
    TRANSITION_REQUEST_PAYMENT,
    TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
    TRANSITION_REQUEST_PAYMENT_AFTER_OFFER_ACCEPTED_PROVIDER,
    TRANSITION_REQUEST_PAYMENT_AFTER_COUNTER_OFFER_ACCEPTED_BY_CUSTOMER,
  ].includes(transition);
};
