import React from 'react';
import PropTypes from 'prop-types';

import config from '../../config';
import LogoImageMobile from './buysell-logo.png';
import LogoImage from '../../assets/footer-initial.png';
import LogoWhitwImage from './logo-white.png';
import IconCollection from '../IconCollection/IconCollection';

const Logo = props => {
  const { className, format, activeCategory, CategorySelected, isLanding, ...rest } = props;

  if (format === 'desktop') {
    return (
      <svg width="269" height="49" viewBox="0 0 269 49" fill="none">
        <path
          d="M0.821289 47.4193V2.21289H16.5557C21.9178 2.21289 25.8514 3.21747 28.3565 5.22666C30.9005 7.19719 32.1726 9.8439 32.1726 13.1668C32.1726 15.2532 31.605 17.1078 30.4701 18.7306C29.3741 20.3535 27.6715 21.4353 25.3623 21.9762V23.3671C28.2977 23.8695 30.5091 25.0673 31.9965 26.9606C33.5229 28.8151 34.2862 31.2301 34.2862 34.2051C34.2862 38.1462 32.9162 41.3339 30.1764 43.7681C27.4366 46.2022 23.2095 47.4193 17.495 47.4193H0.821289ZM7.27942 42.2032H17.4364C21.0371 42.2032 23.64 41.4884 25.2447 40.0588C26.8887 38.5905 27.7106 36.5235 27.7106 33.8574C27.7106 31.2686 26.9082 29.2595 25.3035 27.8299C23.6988 26.4002 21.1154 25.6855 17.5537 25.6855H7.27942V42.2032ZM7.27942 20.6432H16.6731C19.7261 20.6432 21.9961 20.1023 23.4834 19.0204C24.9708 17.9386 25.7145 16.2772 25.7145 14.0361C25.7145 9.63138 22.7006 7.42902 16.6731 7.42902H7.27942V20.6432ZM59.45 48.2307C53.1876 48.2307 48.5885 46.55 45.653 43.1884C42.7566 39.827 41.3085 34.9972 41.3085 28.6993V2.21289H48.0015V28.989C48.0015 33.8574 48.9212 37.4121 50.7608 39.6532C52.6395 41.894 55.5751 43.0146 59.5674 43.0146C63.5596 43.0146 66.4756 41.894 68.3152 39.6532C70.194 37.4121 71.1333 33.8574 71.1333 28.989V2.21289H77.5915V28.6993C77.5915 34.9972 76.1432 39.827 73.2468 43.1884C70.3897 46.55 65.7907 48.2307 59.45 48.2307ZM97.9327 47.4193V27.9458L83.1377 2.56063V2.21289H90.5352L98.1676 15.4271C98.6765 16.2772 99.1461 17.1658 99.5766 18.0932C100.007 19.0204 100.477 20.1023 100.986 21.3387H102.395C102.904 20.1023 103.354 19.0204 103.745 18.0932C104.176 17.1658 104.645 16.2772 105.154 15.4271L112.669 2.21289H119.303V2.56063L104.508 28.0617V47.4193H97.9327Z"
          fill="#D7282F"
          style={{ fill: 'var(--marketplaceInitialDefaultColor)' }}
        />
        <path
          d="M141.31 48.2887C137.748 48.2887 134.735 47.8251 132.269 46.8978C129.842 45.9318 127.866 44.676 126.339 43.1306C124.852 41.5851 123.736 39.9044 122.993 38.0884C122.288 36.2336 121.877 34.4176 121.76 32.6403H130.919C130.997 34.0313 131.427 35.3643 132.21 36.6394C132.993 37.9145 134.167 38.9577 135.733 39.7691C137.298 40.5804 139.255 40.9862 141.604 40.9862C144.148 40.9862 146.027 40.4838 147.24 39.4793C148.492 38.4361 149.119 37.0837 149.119 35.4223C149.119 33.8382 148.571 32.5438 147.475 31.5391C146.418 30.5346 145.009 29.7425 143.248 29.1629L136.613 26.9606C134.5 26.2651 132.465 25.4151 130.508 24.4104C128.59 23.3672 127.004 22.0149 125.752 20.3535C124.5 18.6534 123.873 16.4896 123.873 13.8623C123.873 11.1576 124.558 8.87798 125.928 7.02335C127.337 5.16873 129.255 3.75845 131.682 2.79248C134.148 1.82653 136.966 1.34357 140.136 1.34357C144.207 1.34357 147.416 2.01974 149.765 3.37206C152.113 4.7244 153.816 6.44379 154.872 8.53024C155.968 10.578 156.634 12.7225 156.868 14.9635H147.71C147.631 13.8816 147.318 12.8577 146.77 11.8917C146.261 10.8872 145.439 10.0757 144.305 9.45754C143.169 8.83934 141.643 8.53024 139.725 8.53024C137.572 8.53024 135.968 8.97458 134.911 9.86325C133.893 10.7519 133.384 11.8724 133.384 13.2248C133.384 14.693 133.913 15.8714 134.97 16.7601C136.065 17.6489 137.514 18.4022 139.314 19.0204L145.948 21.2229C148.101 21.9184 150.136 22.7877 152.054 23.8309C153.972 24.8742 155.538 26.2651 156.751 28.0038C158.004 29.7038 158.63 31.9449 158.63 34.7268C158.63 38.9383 157.103 42.2613 154.05 44.6954C151.037 47.0909 146.79 48.2887 141.31 48.2887ZM165.534 47.4194V2.21292H196.768V9.86325H175.281V19.8319H191.837V26.9026H175.281V39.7691H197.121V47.4194H165.534ZM204.27 47.4194V2.21292H214.133V39.7691H233.272V47.4194H204.27ZM239.496 47.4194V2.21292H249.36V39.7691H268.499V47.4194H239.496Z"
          fill="#8E8E8E"
        />
      </svg>
    );
  } else if (format === 'footer') {
    if (!CategorySelected && isLanding) {
      return (
        <img
          className={className}
          src={LogoImage}
          style={{ height: 56 }}
          alt={config.siteTitle}
          {...rest}
        />
      );
    } else {
      return <IconCollection icon="footerLogo" />;
    }
    // return <img className={className} src={LogoWhitwImage} alt={config.siteTitle} {...rest} />;
  }

  return <img className={className} src={LogoImageMobile} alt={config.siteTitle} {...rest} />;
};

const { oneOf, string } = PropTypes;

Logo.defaultProps = {
  className: null,
  format: 'desktop',
};

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile', 'footer']),
};

export default Logo;
