import unionWith from 'lodash/unionWith';

import config from '../../config';
import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { getUserBagData, getUserSaveLaterData, localBagData } from '../../util/dataExtractor';
import { saveLaterItemReminderEmailToUser } from '../../util/api';
import { isStockInUse } from '../../util/search';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 24;

// ================ Action types ================ //

export const FETCH_LISTING_REQUEST = 'app/CartPage/FETCH_LISTING_REQUEST';
export const FETCH_LISTING_SUCCESS = 'app/CartPage/FETCH_LISTING_SUCCESS';
export const FETCH_LISTING_ERROR = 'app/CartPage/FETCH_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  fetchListingInProgress: false,
  fetchListingError: null,
  currentPageResultIds: [],
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTING_REQUEST:
      return {
        ...state,
        fetchListingInProgress: true,
        currentPageResultIds: [],
        fetchListingError: null,
      };
    case FETCH_LISTING_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        fetchListingInProgress: false,
      };
    case FETCH_LISTING_ERROR:
      // eslint-disable-next-line no-console
      return { ...state, fetchListingInProgress: false, fetchListingError: payload };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const fetchListingRequest = () => ({
  type: FETCH_LISTING_REQUEST,
});

export const fetchListingSuccess = response => ({
  type: FETCH_LISTING_SUCCESS,
  payload: { data: response.data },
});

export const fetchListingError = e => ({
  type: FETCH_LISTING_ERROR,
  error: true,
  payload: e,
});

export const saveLaterNotification = emailData => async (dispatch, getState, sdk) => {
  try {
    const result = await saveLaterItemReminderEmailToUser(emailData);
    if (result) {
      return result;
    }
  } catch (error) { }
};



export const fetchListings = (params) => async (dispatch, getState, sdk) => {  
  const { bookmarks,
    saveLaterItems} = params;   
  dispatch(fetchListingRequest());

  try {
    const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
    const aspectRatio = aspectHeight / aspectWidth;

    const filteredIds = bookmarks.map(listing => {
      if (typeof listing === 'object') return listing.id
      else return listing
    });
    const minStockMaybe = isStockInUse(config) ? { minStock: 1 } : {};

   const modifiedIds = [
    ...filteredIds,
    ...(saveLaterItems ? saveLaterItems.map(e => e.id) : []),
  ];
    const queryParams = {
      // ...minStockMaybe,
      ids: modifiedIds,
      perPage: RESULT_PAGE_SIZE,
      include: ['author', 'images', 'currentStock'],
      'fields.listing': ['title', 'geolocation', 'price', 'description', 'publicData'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      'limit.images': 1,
    }
    const response = await sdk.listings.query(queryParams);     
    dispatch(addMarketplaceEntities(response));
    dispatch(fetchListingSuccess(response));
    return response;

  } catch (error) {
    dispatch(fetchListingError(storableError(error)));

  }
}

export const loadData = () => async (dispatch, getState, sdk) => {
  try {
    const currentUser = await dispatch(fetchCurrentUser());
    const bookmarks = currentUser?.id && getUserBagData(currentUser) || localBagData();
    const saveLaterItems = !!currentUser?.id ? getUserSaveLaterData(currentUser) : [];
    const fetchListingArgs = {
      bookmarks,
      saveLaterItems
    }
    if (bookmarks.length > 0 || saveLaterItems.length > 0) {
      dispatch(fetchListings(fetchListingArgs))
    }
  } catch (error) {
  }
};
