import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Topbar.module.css';

const MenuIcon = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootMenuIcon, className);

  return (
    <svg
      className={classes}
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 29.25H32.5C33.3937 29.25 34.125 28.5187 34.125 27.625C34.125 26.7313 33.3937 26 32.5 26H6.5C5.60625 26 4.875 26.7313 4.875 27.625C4.875 28.5187 5.60625 29.25 6.5 29.25ZM6.5 21.125H32.5C33.3937 21.125 34.125 20.3938 34.125 19.5C34.125 18.6062 33.3937 17.875 32.5 17.875H6.5C5.60625 17.875 4.875 18.6062 4.875 19.5C4.875 20.3938 5.60625 21.125 6.5 21.125ZM4.875 11.375C4.875 12.2687 5.60625 13 6.5 13H32.5C33.3937 13 34.125 12.2687 34.125 11.375C34.125 10.4812 33.3937 9.75 32.5 9.75H6.5C5.60625 9.75 4.875 10.4812 4.875 11.375Z"
        fill="#3065A1"
      />
    </svg>
  );
};

const { string } = PropTypes;

MenuIcon.defaultProps = {
  className: null,
  rootClassName: null,
};

MenuIcon.propTypes = {
  className: string,
  rootClassName: string,
};

export default MenuIcon;
