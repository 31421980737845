// These helpers are calling FTW's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import { types as sdkTypes, transit } from './sdkLoader';
import config from '../config';
import Decimal from 'decimal.js';

export const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the same domain and port as the frontend
  return `${window.location.origin}`;
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: config.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const post = (path, body) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: serialize(body),
  };
  return window.fetch(url, options).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }
    return res.text();
  });
};

const get = async path => {
  if (typeof window === 'undefined') {
    // Handle server-side or non-browser environment
    return Promise.resolve(' ');
  }

  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'GET',
    credentials: 'include',
  };

  try {
    const res = await window.fetch(url, options);
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      const data = await res.json();
      let e = new Error();
      e = Object.assign(e, data);
      // throw e;
    }

    if (contentType === 'application/transit+json') {
      return await res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return await res.json();
    }

    return await res.text();
  } catch (error) {
    // Handle any fetch-related errors here
    // throw error; // Optionally rethrow the error for further handling
  }
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

export const userNameValidation = body => {
  return post('/api/user-name-validate', body);
};

export const reportingUserEmail = body => {
  return post('/api/reporting-user-email', body);
};

export const getListings = body => {
  return post('/api/get-listings', body);
};

export const getShipingLabel = body => {
  return post('/api/get-shipping-label', body);
};

export const deleteAccountToAdmin = body => {
  return post('/api/delete-account', body);
};

export const trackingShippedOrder = body => {
  return post('/api/track-order', body);
};

export const getServiceCodes = body => {
  return get('/api/get-service-code', body);
};

export const addressVerification = body => {
  return post('/api/address-verified', body);
};

export const deleteStock = body => {
  return post('/api/delete-stock', body);
};

export const createShippment = body => {
  return post('/api/create-shippment', body);
};

export const getRateOptions = body => {
  return post('/api/get-rate-options', body);
};

export const getBlogs = body => {
  return get('/api/get-blogs', body);
};

export const updateTransactionMetadata = body => {
  return post('/api/update-metaData', body);
};

export const getHeroSectionData = body => {
  return get('/api/get-hero-clothing', body);
};

export const cartItemReminderEmailToUser = body => {
  return post('/api/cart-item-notify', body);
};

export const cancelCartReminderEmailToUser = body => {
  return post('/api/cancel-cart-item-notify', body);
};

export const saveLaterItemReminderEmailToUser = body => {
  return post('/api/save-later-notify', body);
};

export const getHeroSectionSneakersData = body => {
  return get('/api/get-hero-sneaker', body);
};

export const getHeroSectionStreetwearData = body => {
  return get('/api/get-hero-streetwear', body);
};

export const getHeroSectionVintageData = body => {
  return get('/api/get-hero-vintage', body);
};

export const fetchUserStripeAccount = body => {
  return post('/api/fetch-user-stripe', body);
};

export const fetchAuthorSalesData = body => {
  return post('/api/fetch-author-sales', body);
};

export const notifyUserToPayouts = body => {
  return post('/api/notify-user-payouts', body);
};

export const disputeOrderEmailToAdmin = body => {
  return post('/api/dispute-order-to-admin', body);
};

export const closeOrOpenAllListings = body => {
  return post('/api/open-or-close-listings', body);
};

export const sendVerificationCode = body => {
  return post('/api/send-verification-code', body);
};

export const verifyCode = body => {
  return post('/api/verify-code', body);
};

export const userPhoneNumberValidation = body => {
  return post('/api/phone-number-validation', body);
};

export const trackShipingOrderWebhook = body => {
  return post('/api/track-shipping-order', body);
};

export const getUserAddressLatLng = body => {
  return post('/api/shipping-address-latlng', body);
};

export const updateOrderNumber = body => {
  return post('/api/update-order-number', body);
};

export const fetchOrderNumber = body => {
  return get('/api/get-order-number', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the order data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/initiate-privileged', body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Flex with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', body);
};

export const searchBrand = body => {
  return get(`/api/search-brand?q=${body}`);
};

export const getStateTaxes = body => {
  return post(`/api/get-tax-rates`, body);
};

export const getUserFollowCounts = body => {
  return get(`/api/user-follower-count?q=${body}`);
};

export const followUser = body => {
  return post('/api/follow-user', body);
};
export const unfollowUser = body => {
  return post('/api/unfollow-user', body);
};

export const updateAuthorListings = body => {
  return post('/api/update-listing', body);
};

export const notifyUserToPriceDrop = body => {
  return post('/api/notify-user', body);
};

export const notifyUserToNewItem = body => {
  return post('/api/notify-user-new-item', body);
};

export const deleteImageFromCloudinary = body => {
  return post('/api/delete-image-cloudinary', body);
};

export const deleteMultipleImagesFromCloudinary = body => {
  return post('/api/delete-multi-image-cloudinary', body);
};

export const cancelShippingLabel = body => {
  return post('/api/cancel-shipping', body);
};