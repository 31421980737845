import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { IconShare } from '../../components';
import css from './SocialShare.module.css';
import { FormattedMessage } from 'react-intl';
import { createSlug } from '../../util/urlHelpers';
import { ToastContainer, toast } from 'react-toastify';
import './ReactToastify.css';

const SocialShare = props => {
  const { url, displayName, isListing, listingId, title, isProfile } = props;

  const slug = isProfile ? null : createSlug(title);

  const urlShare = isProfile
    ? `${process.env.REACT_APP_MARKETPLACE_ROOT_URL}/u/${listingId}`
    : `${process.env.REACT_APP_MARKETPLACE_ROOT_URL}/l/${slug}/${listingId}`;

  const [copyText, setCopyText] = useState(false);
  const [urlToCopy, setUrlToCopy] = useState(urlShare);

  const notify = () =>
    toast('Copied!', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });

  useEffect(() => {
    notify();
    const timer = setTimeout(() => {
      setCopyText(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, [copyText]);

  const copyToClipboard = () => {
    // Create a text input element
    const input = document.createElement('input');
    input.value = urlToCopy;

    // Append it to the DOM
    document.body.appendChild(input);

    // Select the text in the input
    input.select();

    // Copy the selected text to the clipboard
    document.execCommand('copy');

    // Remove the input element
    document.body.removeChild(input);
    setCopyText(true);
    // alert('URL copied to clipboard');
  };

  const displayTitle = isListing
    ? `Share @${displayName}’s Listing`
    : `Share ${displayName}’s Store`;
  // const emailBody = "Share this profile on Vendor Tree";

  return (
    <>
      <div className={css.shareModalWrapper}>
        <h4 className={css.modalTitle}>{displayTitle}</h4>
        {copyText ? (
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover={false}
            theme="dark"
          />
        ) : null}
        <ul className={css.socialLinks}>
          <li onClick={copyToClipboard}>
            <div className={css.iconButton}>
              <IconShare type="link" />
              {/* <span>Copy link </span> */}
            </div>
          </li>
          <li>
            <FacebookShareButton url={urlShare} quote={displayTitle} className={css.facebookButton}>
              <IconShare type="fb" />
            </FacebookShareButton>
          </li>
          <li>
            <LinkedinShareButton title={displayTitle} url={urlShare} className={css.linkedinButton}>
              <IconShare type="linkedinicon" />
            </LinkedinShareButton>
          </li>

          <li className={css.twitterButton}>
            <TwitterShareButton title={displayTitle} className={css.linkedinButton} url={urlShare}>
              <IconShare type="twitter" />
            </TwitterShareButton>
          </li>
          <li className={css.whatsAppButton}>
            <WhatsappShareButton url={urlShare} title={displayTitle} className={css.linkedinButton}>
              <IconShare type="whatsapp" />
            </WhatsappShareButton>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SocialShare;
