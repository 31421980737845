/**
 * Usage without sizes:
 *   <ResponsiveImage
 *     alt="ListingX"
 *     image={imageDataFromSDK}
 *     variants={['landscape-crop', 'landscape-crop2x']}
 *   />
 *   // produces:
 *   <img
 *     alt="ListingX"
 *     src="url/to/landscape-crop.jpg"
 *     srcSet="url/to/landscape-crop.jpg 400w, url/to/landscape-crop2x.jpg 800w" />
 *
 * Usage with sizes:
 *   <ResponsiveImage
 *     alt="ListingX"
 *     image={imageDataFromSDK}
 *     variants={['landscape-crop', 'landscape-crop2x']}
 *     sizes="(max-width: 600px) 100vw, 50vw"
 *   />
 *   // produces:
 *   <img
 *     alt="ListingX"
 *     src="url/to/landscape-crop.jpg"
 *     srcSet="url/to/landscape-crop.jpg 400w, url/to/landscape-crop2x.jpg 800w"
 *     sizes="(max-width: 600px) 100vw, 50vw" />
 *
 *   // This means that below 600px image will take as many pixels there are available on current
 *   // viewport width (100vw) - and above that image will only take 50% of the page width.
 *   // Browser decides which image it will fetch based on current screen size.
 *
 * NOTE: for all the possible image variant names and their respective
 * sizes, see the API documentation.
 */

import React from 'react';
import { arrayOf, oneOfType, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { CLOSED, DISCOUNT, NOT_FOR_SALE, VIDEO_TYPE, propTypes } from '../../util/types';

import NoImageIcon from './NoImageIcon';
import css from './ResponsiveImage.module.css';
import IconCollection from '../IconCollection/IconCollection';
import {
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_MARK_DELIVERED_OPERTOR,
  TRANSITION_SHIPPING_LABEL,
} from '../../util/transaction';

const ResponsiveImage = props => {
  const {
    className,
    rootClassName,
    alt,
    noImageMessage,
    image,
    variants,
    dimensions,
    handleFavouriteItems,
    index,
    isListingCards,
    listingVideoAndImagesUrl,
    isManage,
    availability,
    currentStock,
    params,
    discountPercentage,
    state,
    lastTransition,
    isCartPanel,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  if (image == null || variants.length === 0) {
    const noImageClasses = classNames(rootClassName || css.root, css.noImageContainer, className);

    const noImageMessageText = noImageMessage || <FormattedMessage id="ResponsiveImage.noImage" />;
    return (
      <div className={noImageClasses}>
        <div className={css.noImageWrapper}>
          {/* <NoImageIcon className={css.noImageIcon} manageListingPage={true} /> */}
          {listingVideoAndImagesUrl?.length > 0 &&
          listingVideoAndImagesUrl[0]?.type === VIDEO_TYPE ? (
            <video className={classes} controls={false}>
              <source src={listingVideoAndImagesUrl[0]?.url} type="video/mp4" />
            </video>
          ) : (
            <img className={classes} src={listingVideoAndImagesUrl?.length && listingVideoAndImagesUrl[0]?.url} alt="Image" />
          )}
          {/* <div className={css.noImageText}>{noImageMessageText}</div> */}
        </div>
      </div>
    );
  }

  const imageVariants = image.attributes.variants;

  const srcSet = variants
    .map(variantName => {
      const variant = imageVariants[variantName];

      if (!variant) {
        // Variant not available (most like just not loaded yet)
        return null;
      }
      return `${variant.url} ${variant.width}w`;
    })
    .filter(v => v != null)
    .join(', ');

  const imgProps = {
    className: classes,
    srcSet,
    ...rest,
  };

  return (
    <>
      {listingVideoAndImagesUrl?.length ? (
        <>
          {listingVideoAndImagesUrl.length > 0 &&
          listingVideoAndImagesUrl[0].type === VIDEO_TYPE ? (
            <video className={classes} controls={false}>
              <source src={listingVideoAndImagesUrl[0].url} type="video/mp4" />
            </video>
          ) : (
            <img className={classes} src={listingVideoAndImagesUrl[0].url} alt="Image" />
          )}
        </>
      ) : (
        <img alt={alt} {...imgProps} />
      )}
      {(isListingCards||isCartPanel) && currentStock === 0 && 
        <div className={css.cardMessages}>
          <span>
                 <FormattedMessage id="ManageListingCard.itemSold" />
              </span> 
        </div>}
      {isListingCards ? (
        <div
          className={css.favorite}
          onClick={e => {
            e.preventDefault();
            handleFavouriteItems();
          }}
        >
          {index > -1 ? (
            <span className={css.bookmark}>
              <IconCollection icon="like" />
            </span>
          ) : (
            <span>
              <IconCollection icon="dislike" />
            </span>
          )}
        </div>
      ) : isManage &&
        params.state !== DISCOUNT &&
        state !== CLOSED &&
        (availability === NOT_FOR_SALE || currentStock === 0) ? (
        <div className={css.cardMessages}>
          {state === CLOSED ? (
            <span>
              <FormattedMessage id="ManageListingCard.closed" />
            </span>
          ) : (
            <>
              <span>
                {availability === NOT_FOR_SALE ? (
                  <FormattedMessage id="ManageListingCard.NotForSale" />
                ) : currentStock === 0 ? (
                  <FormattedMessage id="ManageListingCard.itemSold" />
                ) : null}
              </span>
            </>
          )}
        </div>
      ) : isManage && params.state === DISCOUNT && state !== CLOSED ? (
        <div className={classNames(css.cardMessages, css.bgDark)}>
          {state === CLOSED ? (
            <span>
              <FormattedMessage id="ManageListingCard.closed" />
            </span>
          ) : (
            <>
              {discountPercentage ? (
                <span>
                  {discountPercentage} <FormattedMessage id="ManageListingCard.discountText" />
                </span>
              ) : null}
            </>
          )}
        </div>
      ) : isManage && state === CLOSED ? (
        <div className={css.cardMessages}>
          <span>
            <FormattedMessage id="ManageListingCard.closed" />
          </span>
        </div>
      ) : lastTransition === TRANSITION_CONFIRM_PAYMENT ? (
        <div className={css.cardMessages}>
          <span>
            <FormattedMessage id="ManageListingCard.shippNow" />
          </span>
        </div>
      ) : lastTransition === TRANSITION_SHIPPING_LABEL ? (
        <div className={css.cardMessages}>
          <span>
            <FormattedMessage id="ManageListingCard.shipped" />
          </span>
        </div>
      ) : lastTransition === TRANSITION_MARK_DELIVERED_OPERTOR ? (
        <div className={css.cardMessages}>
          <span>
            <FormattedMessage id="ManageListingCard.delivered" />
          </span>
        </div>
      ) : null}
    </>
  );
};

ResponsiveImage.defaultProps = {
  className: null,
  rootClassName: null,
  image: null,
  noImageMessage: null,
};

ResponsiveImage.propTypes = {
  className: string,
  rootClassName: string,
  alt: string.isRequired,
  image: oneOfType([propTypes.image, propTypes.imageAsset]),
  variants: arrayOf(string).isRequired,
  noImageMessage: string,
};

export default ResponsiveImage;
