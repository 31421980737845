import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconClose.module.css';
const SIZE_SMALL = 'small';
const WITH_BORDER = 'IconWithBorder';

const IconClose = props => {
  const { className, rootClassName, size } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (size === SIZE_SMALL) {
    return (
      <svg className={classes} width="9" height="9" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.175 8.396l2.482-2.482 2.482 2.482a.889.889 0 1 0 1.258-1.257L5.914 4.657l2.482-2.483A.89.89 0 0 0 7.139.917L4.657 3.4 2.175.918A.888.888 0 1 0 .917 2.174L3.4 4.657.918 7.139a.889.889 0 1 0 1.257 1.257"
          fillRule="evenodd"
        />
      </svg>
    );
  } else if (size === WITH_BORDER) {
    return (
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
        <path
          d="M22.8036 20.9464C23.0502 21.1929 23.1887 21.5273 23.1887 21.876C23.1887 22.2247 23.0502 22.5592 22.8036 22.8057C22.557 23.0523 22.2226 23.1908 21.8739 23.1908C21.5252 23.1908 21.1908 23.0523 20.9442 22.8057L14 15.8593L7.05361 22.8035C6.80704 23.0501 6.47262 23.1886 6.12392 23.1886C5.77522 23.1886 5.4408 23.0501 5.19423 22.8035C4.94766 22.557 4.80914 22.2226 4.80914 21.8739C4.80914 21.5252 4.94766 21.1907 5.19423 20.9442L12.1406 13.9999L5.19642 7.05354C4.94985 6.80697 4.81133 6.47255 4.81133 6.12385C4.81133 5.77515 4.94985 5.44073 5.19642 5.19417C5.44299 4.9476 5.77741 4.80908 6.12611 4.80908C6.47481 4.80908 6.80923 4.9476 7.05579 5.19417L14 12.1406L20.9464 5.19307C21.193 4.9465 21.5274 4.80798 21.8761 4.80798C22.2248 4.80798 22.5592 4.9465 22.8058 5.19307C23.0524 5.43964 23.1909 5.77406 23.1909 6.12276C23.1909 6.47146 23.0524 6.80588 22.8058 7.05245L15.8594 13.9999L22.8036 20.9464Z"
          fill="#4A4A4A"
        />
        <path
          d="M22.8036 20.9464C23.0502 21.1929 23.1887 21.5273 23.1887 21.876C23.1887 22.2247 23.0502 22.5592 22.8036 22.8057C22.557 23.0523 22.2226 23.1908 21.8739 23.1908C21.5252 23.1908 21.1908 23.0523 20.9442 22.8057L14 15.8593L7.05361 22.8035C6.80704 23.0501 6.47262 23.1886 6.12392 23.1886C5.77522 23.1886 5.4408 23.0501 5.19423 22.8035C4.94766 22.557 4.80914 22.2226 4.80914 21.8739C4.80914 21.5252 4.94766 21.1907 5.19423 20.9442L12.1406 13.9999L5.19642 7.05354C4.94985 6.80697 4.81133 6.47255 4.81133 6.12385C4.81133 5.77515 4.94985 5.44073 5.19642 5.19417C5.44299 4.9476 5.77741 4.80908 6.12611 4.80908C6.47481 4.80908 6.80923 4.9476 7.05579 5.19417L14 12.1406L20.9464 5.19307C21.193 4.9465 21.5274 4.80798 21.8761 4.80798C22.2248 4.80798 22.5592 4.9465 22.8058 5.19307C23.0524 5.43964 23.1909 5.77406 23.1909 6.12276C23.1909 6.47146 23.0524 6.80588 22.8058 7.05245L15.8594 13.9999L22.8036 20.9464Z"
          fill="#4A4A4A"
        />
        <path
          d="M22.8036 20.9464C23.0502 21.1929 23.1887 21.5273 23.1887 21.876C23.1887 22.2247 23.0502 22.5592 22.8036 22.8057C22.557 23.0523 22.2226 23.1908 21.8739 23.1908C21.5252 23.1908 21.1908 23.0523 20.9442 22.8057L14 15.8593L7.05361 22.8035C6.80704 23.0501 6.47262 23.1886 6.12392 23.1886C5.77522 23.1886 5.4408 23.0501 5.19423 22.8035C4.94766 22.557 4.80914 22.2226 4.80914 21.8739C4.80914 21.5252 4.94766 21.1907 5.19423 20.9442L12.1406 13.9999L5.19642 7.05354C4.94985 6.80697 4.81133 6.47255 4.81133 6.12385C4.81133 5.77515 4.94985 5.44073 5.19642 5.19417C5.44299 4.9476 5.77741 4.80908 6.12611 4.80908C6.47481 4.80908 6.80923 4.9476 7.05579 5.19417L14 12.1406L20.9464 5.19307C21.193 4.9465 21.5274 4.80798 21.8761 4.80798C22.2248 4.80798 22.5592 4.9465 22.8058 5.19307C23.0524 5.43964 23.1909 5.77406 23.1909 6.12276C23.1909 6.47146 23.0524 6.80588 22.8058 7.05245L15.8594 13.9999L22.8036 20.9464Z"
          fill="black"
        />
        <rect x="0.75" y="0.75" width="26.5" height="26.5" stroke="#3065A1" strokeWidth="1.5" />
      </svg>
    );
  }

  return (
    <svg
      className={classes}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6036 1.11893L8.44707 7.27608L2.29057 1.11893C2.1425 0.983414 1.94786 0.91026 1.74719 0.914707C1.54653 0.919153 1.35532 1.00086 1.21339 1.1428C1.07147 1.28474 0.989772 1.47597 0.985326 1.67666C0.98088 1.87734 1.05403 2.07201 1.18953 2.22009L7.3429 8.37725L1.18796 14.5328C1.11277 14.6045 1.05266 14.6905 1.01117 14.7857C0.969673 14.881 0.947635 14.9835 0.946349 15.0874C0.945062 15.1913 0.964553 15.2944 1.00367 15.3906C1.0428 15.4868 1.10076 15.5743 1.17416 15.6478C1.24755 15.7213 1.33491 15.7794 1.43107 15.8187C1.52724 15.8579 1.63029 15.8776 1.73416 15.8764C1.83803 15.8753 1.94062 15.8534 2.0359 15.812C2.13119 15.7707 2.21724 15.7107 2.28901 15.6356L8.44707 9.47997L14.6036 15.6371C14.7516 15.7726 14.9463 15.8458 15.1469 15.8413C15.3476 15.8369 15.5388 15.7552 15.6807 15.6133C15.8227 15.4713 15.9044 15.2801 15.9088 15.0794C15.9133 14.8787 15.8401 14.684 15.7046 14.536L9.54812 8.37881L15.7046 2.22009C15.7798 2.14842 15.8399 2.06244 15.8814 1.96721C15.9229 1.87197 15.9449 1.7694 15.9462 1.66552C15.9475 1.56165 15.928 1.45856 15.8889 1.36233C15.8498 1.26609 15.7918 1.17865 15.7184 1.10514C15.645 1.03163 15.5577 0.973535 15.4615 0.934273C15.3653 0.895011 15.2623 0.875372 15.1584 0.876512C15.0546 0.877651 14.952 0.899545 14.8567 0.940907C14.7614 0.982269 14.6753 1.04226 14.6036 1.11737V1.11893Z"
        fill="black"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconClose.defaultProps = {
  className: null,
  rootClassName: null,
};

IconClose.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconClose;
