import isEmpty from 'lodash/isEmpty';
import { clearCurrentUser, fetchCurrentUser } from './user.duck';
import { createUserWithIdp, userNameValidation } from '../util/api';
import { storableError } from '../util/errors';
import * as log from '../util/log';
import { isEmailValid, localBagData } from '../util/dataExtractor';

const authenticated = authInfo => authInfo && authInfo.isAnonymous === false;
const uploadURL = process.env.REACT_APP_CLOUDINARY_URL;
const folderName = 'Users - Profile Pictures';
// ================ Action types ================ //

export const AUTH_INFO_REQUEST = 'app/Auth/AUTH_INFO_REQUEST';
export const AUTH_INFO_SUCCESS = 'app/Auth/AUTH_INFO_SUCCESS';

export const LOGIN_REQUEST = 'app/Auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'app/Auth/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'app/Auth/LOGIN_ERROR';

export const LOGOUT_REQUEST = 'app/Auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'app/Auth/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'app/Auth/LOGOUT_ERROR';

export const SIGNUP_REQUEST = 'app/Auth/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'app/Auth/SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'app/Auth/SIGNUP_ERROR';

export const CONFIRM_REQUEST = 'app/Auth/CONFIRM_REQUEST';
export const CONFIRM_SUCCESS = 'app/Auth/CONFIRM_SUCCESS';
export const CONFIRM_ERROR = 'app/Auth/CONFIRM_ERROR';

export const UPLOAD_IMAGE_REQUEST = 'app/Auth/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/Auth/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/Auth/UPLOAD_IMAGE_ERROR';

// Generic user_logout action that can be handled elsewhere
// E.g. src/reducers.js clears store as a consequence
export const USER_LOGOUT = 'app/USER_LOGOUT';

// ================ Reducer ================ //

const initialState = {
  image: null,
  uploadImageError: null,
  uploadInProgress: false,
  uploadInProgressImage: false,
  isAuthenticated: false,

  // scopes associated with current token
  authScopes: [],

  // auth info
  authInfoLoaded: false,

  // login
  loginError: null,
  loginInProgress: false,

  // logout
  logoutError: null,
  logoutInProgress: false,

  // signup
  signupError: null,
  signupInProgress: false,

  // confirm (create use with idp)
  confirmError: null,
  confirmInProgress: false,
  isUsernameExits: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case AUTH_INFO_REQUEST:
      return state;
    case AUTH_INFO_SUCCESS:
      return {
        ...state,
        authInfoLoaded: true,
        isAuthenticated: authenticated(payload),
        authScopes: payload.scopes,
      };

    case LOGIN_REQUEST:
      return {
        ...state,
        loginInProgress: true,
        loginError: null,
        logoutError: null,
        signupError: null,
      };
    case LOGIN_SUCCESS:
      return { ...state, loginInProgress: false, isAuthenticated: true };
    case LOGIN_ERROR:
      return { ...state, loginInProgress: false, loginError: payload };

    case LOGOUT_REQUEST:
      return { ...state, logoutInProgress: true, loginError: null, logoutError: null };
    case LOGOUT_SUCCESS:
      return { ...state, logoutInProgress: false, isAuthenticated: false, authScopes: [] };
    case LOGOUT_ERROR:
      return { ...state, logoutInProgress: false, logoutError: payload };

    case SIGNUP_REQUEST:
      return { ...state, signupInProgress: true, loginError: null, signupError: null };
    case SIGNUP_SUCCESS:
      return { ...state, signupInProgress: false, isUsernameExits: payload };
    case SIGNUP_ERROR:
      return { ...state, signupInProgress: false, signupError: payload };

    case CONFIRM_REQUEST:
      return { ...state, confirmInProgress: true, loginError: null, confirmError: null };
    case CONFIRM_SUCCESS:
      return { ...state, confirmInProgress: false, isAuthenticated: true };
    case CONFIRM_ERROR:
      return { ...state, confirmInProgress: false, confirmError: payload };
    case UPLOAD_IMAGE_REQUEST:
      // payload.params: { id: 'tempId', file }
      return {
        ...state,
        image: { ...payload.params },
        uploadInProgress: true,
        uploadInProgressImage: false,
        uploadImageError: null,
      };
    case UPLOAD_IMAGE_SUCCESS: {
      // payload: { id: 'tempId', uploadedImage }
      const { id, uploadedImage } = payload;
      const { file } = state.image || {};
      const image = { id, imageId: uploadedImage.id, file, uploadedImage };
      return { ...state, image, uploadInProgress: false };
    }
    case UPLOAD_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      return { ...state, image: null, uploadInProgress: false, uploadImageError: payload.error };
    }

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const authenticationInProgress = state => {
  const { loginInProgress, logoutInProgress, signupInProgress } = state.Auth;
  return loginInProgress || logoutInProgress || signupInProgress;
};

// ================ Action creators ================ //

export const authInfoRequest = () => ({ type: AUTH_INFO_REQUEST });
export const authInfoSuccess = info => ({ type: AUTH_INFO_SUCCESS, payload: info });

export const loginRequest = () => ({ type: LOGIN_REQUEST });
export const loginSuccess = () => ({ type: LOGIN_SUCCESS });
export const loginError = error => ({ type: LOGIN_ERROR, payload: error, error: true });

export const logoutRequest = () => ({ type: LOGOUT_REQUEST });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
export const logoutError = error => ({ type: LOGOUT_ERROR, payload: error, error: true });

export const signupRequest = () => ({ type: SIGNUP_REQUEST });
export const signupSuccess = data => ({ type: SIGNUP_SUCCESS, payload: data });
export const signupError = error => ({ type: SIGNUP_ERROR, payload: error, error: true });

export const confirmRequest = () => ({ type: CONFIRM_REQUEST });
export const confirmSuccess = () => ({ type: CONFIRM_SUCCESS });
export const confirmError = error => ({ type: CONFIRM_ERROR, payload: error, error: true });

// SDK method: images.upload
export const uploadImageRequest = params => ({ type: UPLOAD_IMAGE_REQUEST, payload: { params } });
export const uploadImageSuccess = result => ({ type: UPLOAD_IMAGE_SUCCESS, payload: result.data });
export const uploadImageError = error => ({
  type: UPLOAD_IMAGE_ERROR,
  payload: error,
  error: true,
});

export const userLogout = () => ({ type: USER_LOGOUT });

// ================ Thunks ================ //

export const authInfo = () => (dispatch, getState, sdk) => {
  dispatch(authInfoRequest());
  return sdk
    .authInfo()
    .then(info => dispatch(authInfoSuccess(info)))
    .catch(e => {
      // Requesting auth info just reads the token from the token
      // store (i.e. cookies), and should not fail in normal
      // circumstances. If it fails, it's due to a programming
      // error. In that case we mark the operation done and dispatch
      // `null` success action that marks the user as unauthenticated.
      log.error(e, 'auth-info-failed');
      dispatch(authInfoSuccess(null));
    });
};

export const login = (username, password) => async (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(loginRequest());

  try {
    const isValid = isEmailValid(username);
    const localBookmarks = localBagData();

    const updateProfile = async () => {
      if (localBookmarks.length > 0) {
        try {
          const { data: { data: currentUser = {} } = {} } = await sdk.currentUser.show();
          const existingBookmarks = currentUser?.id && currentUser?.attributes?.profile?.protectedData?.bookmarks || [];
          
          const updatedBookmarks = Array.from(new Set([...existingBookmarks, ...localBookmarks]));

          // Extract all the object ids from the array of objects
          let objectIds = updatedBookmarks.filter(item => typeof item === 'object').map(item => item.id);

          // Filter out the direct id values that match any of the object ids
          let bookmarksData = updatedBookmarks.filter(item => typeof item !== 'string' || !objectIds.includes(item));
    
          const res = await sdk.currentUser.updateProfile({
            protectedData: { bookmarks: bookmarksData },
          });
    
          if (res) {
            typeof window !== 'undefined' && localStorage.removeItem('localBookmarks');
          }
        } catch (error) {
          console.error('Error updating profile:', error);
        }
      }
    };

    if (isValid) {
      await sdk.login({ username, password });
      dispatch(loginSuccess());
      await updateProfile();
    } else {
      const result = await userNameValidation({ userName: username });
      const { userEmail } = result;
      await sdk.login({ username: userEmail, password });
      dispatch(loginSuccess());
      await updateProfile();
    }

    await dispatch(fetchCurrentUser());
  } catch (e) {
    dispatch(loginError(storableError(e)));
  }
};

export const logout = () => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(logoutRequest());

  // Note that the thunk does not reject when the logout fails, it
  // just dispatches the logout error action.
  return sdk
    .logout()
    .then(() => {
      // The order of the dispatched actions
      dispatch(logoutSuccess());
      typeof window !== 'undefined' && localStorage.removeItem('localBookmarks');
      dispatch(clearCurrentUser());
      log.clearUserId();
      dispatch(userLogout());
    })
    .catch(e => dispatch(logoutError(storableError(e))));
};

export const uploadImage = actionPayload => (dispatch, getState, sdk) => {
  try {
    const id = actionPayload.id;
    dispatch(uploadImageRequest(actionPayload));
  } catch (error) {}
};

export const signup = params => async (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(signupRequest());
  const { email, password, firstName, lastName, userName, ...rest } = params;

  const createUserParams = isEmpty(rest)
    ? { email, password, firstName, lastName }
    : {
        email,
        password,
        firstName,
        lastName,
        protectedData: { userName },
        publicData: { email, userName },
      };

  // We must login the user if signup succeeds since the API doesn't
  // do that automatically.
  const localBookmarks = localBagData();

  const result = await userNameValidation({ userName });
  const { isUserNameAlreadyExit } = result;
  if (!isUserNameAlreadyExit) {
    return sdk.currentUser
      .create(createUserParams)
      .then(() => dispatch(signupSuccess(isUserNameAlreadyExit)))
      .then(() => dispatch(login(email, password)))
      .then(() => {
        const { image } = getState().Auth;
        if (image) {
          sdk.images
            .upload({
              image: image.file,
            })
            .then(res => {
              const { id } = res.data.data;
              sdk.currentUser
                .updateProfile({
                  profileImageId: id,
                })
                .then(() => {
                  dispatch(fetchCurrentUser());
                  const formData = new FormData();
                  const fileType = image.file.type;
                  formData.append('file', image.file);
                  formData.append('upload_preset', process.env.REACT_APP_CLOUDINARY_PRESET);
                  formData.append('resource_type', fileType);
                  formData.append('api_key', process.env.REACT_APP_CLOUDINARY_API_KEY);
                  formData.append('folder', folderName);

                  return fetch(uploadURL, { method: 'POST', body: formData })
                    .then(response => response.text())
                    .then(data => {
                      const res = JSON.parse(data);
                      sdk.currentUser.updateProfile({
                        publicData: {
                          proifleImageUrl: res.secure_url,
                        },
                        protectedData: {
                          bookmarks: localBookmarks,
                        },
                      });
                      console.log('upload successfully!');
                    });
                });
            });
        }
      })
      .catch(e => {
        dispatch(signupError(storableError(e)));
        log.error(e, 'signup-failed', {
          email: params.email,
          firstName: params.firstName,
          lastName: params.lastName,
        });
      });
  } else {
    return dispatch(signupSuccess(isUserNameAlreadyExit));
  }
};

export const signupWithIdp = params => (dispatch, getState, sdk) => {
  dispatch(confirmRequest());
  return createUserWithIdp(params)
    .then(res => {
      return dispatch(confirmSuccess());
    })
    .then(() => dispatch(fetchCurrentUser()))
    .catch(e => {
      log.error(e, 'create-user-with-idp-failed', { params });
      return dispatch(confirmError(storableError(e)));
    });
};
