import React, { useState } from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEM_CUSTOMER_COMMISSION, LINE_ITEM_PROCESSING_FEE, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';
import classNames from 'classnames';
import Modal from '../Modal/Modal';
import IconCollection from '../IconCollection/IconCollection';

const { Money } = sdkTypes;

// Validate the assumption that the commission exists and the amount
// is zero or positive.
const isValidCommission = commissionLineItem => {
  return (
    commissionLineItem &&
    commissionLineItem.lineTotal instanceof Money &&
    commissionLineItem.lineTotal.amount <= 0
  );
};

const LineItemCustomerCommissionMaybe = props => {
  const [isOpen, setOpen] = useState(false);
  const { lineItems, isCustomer, intl } = props;

  const customerCommissionLineItem = lineItems.find(
    item => item.code === LINE_ITEM_PROCESSING_FEE && !item.reversal
  );
  

  // If commission is passed it will be shown as a fee already reduces from the total price
  let commissionItem = null;

  if (isCustomer && customerCommissionLineItem) {
    if (!isValidCommission(customerCommissionLineItem)) {
      // eslint-disable-next-line no-console
      console.error('invalid commission line item:', customerCommissionLineItem);
      throw new Error('Commission should be present and the value should be zero or positive');
    }

    const commission = customerCommissionLineItem.lineTotal;
    const formattedCommission = commission ? formatMoney(intl, commission) : null;

    const infoIcon = (
<span onClick={()=>{setOpen(true)}}><IconCollection icon="storeIcon"/></span>
    );

    commissionItem = (
      <div className={classNames(css.lineItem, css.lineItemComission)}>
        <span className={css.itemLabel}>
          <FormattedMessage id="OrderBreakdown.commissionCustomer" values={{infoIcon}}/>
        </span>
        <span className={css.itemValue}>{formattedCommission}</span>
        <Modal
            className={css.modalStores}
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            onManageDisableScrolling={()=>{}}
          >
            <div className={css.modalContent}>
              <FormattedMessage id="OrderBreakdown.textIcon"/>
            </div>
          </Modal>
      </div>
    );
  }

  return commissionItem;
};

LineItemCustomerCommissionMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  isCustomer: bool.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemCustomerCommissionMaybe;
