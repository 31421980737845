import config from '../../config';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { updatedEntities, denormalisedEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import { getBlogs, getListings, closeOrOpenAllListings } from '../../util/api';
import { searchCustomListingsSuccess } from '../SearchPage/SearchPage.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { showListing } from '../ListingPage/ListingPage.duck';
import { CLOSED, PUBLISHED,  } from '../../util/types';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { fetchOrderTransitions } from '../../util/dataExtractor';
import { stripeCustomer } from '../PaymentMethodsPage/PaymentMethodsPage.duck';
const { UUID } = sdkTypes;
// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 40;

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/MyPurchasesPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/MyPurchasesPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/MyPurchasesPage/FETCH_LISTINGS_ERROR';

export const FETCH_OWN_LISTINGS_REQUEST = 'app/MyPurchasesPage/FETCH_OWN_LISTINGS_REQUEST';
export const FETCH_OWN_LISTINGS_SUCCESS = 'app/MyPurchasesPage/FETCH_OWN_LISTINGS_SUCCESS';
export const FETCH_OWN_LISTINGS_ERROR = 'app/MyPurchasesPage/FETCH_OWN_LISTINGS_ERROR';

export const OPEN_LISTING_REQUEST = 'app/MyPurchasesPage/OPEN_LISTING_REQUEST';
export const OPEN_LISTING_SUCCESS = 'app/MyPurchasesPage/OPEN_LISTING_SUCCESS';
export const OPEN_LISTING_ERROR = 'app/MyPurchasesPage/OPEN_LISTING_ERROR';

export const CLOSE_LISTING_REQUEST = 'app/MyPurchasesPage/CLOSE_LISTING_REQUEST';
export const CLOSE_LISTING_SUCCESS = 'app/MyPurchasesPage/CLOSE_LISTING_SUCCESS';
export const CLOSE_LISTING_ERROR = 'app/MyPurchasesPage/CLOSE_LISTING_ERROR';

export const VACATION_MODE_REQUEST = 'app/MyPurchasesPage/VACATION_MODE_REQUEST';
export const VACATION_MODE_SUCCESS = 'app/MyPurchasesPage/VACATION_MODE_SUCCESS';
export const VACATION_MODE_ERROR = 'app/MyPurchasesPage/VACATION_MODE_ERROR';

export const ADD_OWN_ENTITIES = 'app/MyPurchasesPage/ADD_OWN_ENTITIES';

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/MyPurchasesPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/MyPurchasesPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/MyPurchasesPage/FETCH_ORDERS_OR_SALES_ERROR';
// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],
  currentPageResultDraftIds: [],
  ownEntities: {},
  openingListing: null,
  openingListingError: null,
  closingListing: null,
  closingListingError: null,
  blogDatas: [],
  vacationInProgress: false,

  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  paginations: null,
  transactionRefs: [],
};

const resultIds = data => data.data.map(l => l.id);

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    ownEntities: updatedEntities({ ...state.ownEntities }, apiResponse),
  };
};

const updateListingAttributes = (state, listingEntity) => {
  const oldListing = state.ownEntities.ownListing[listingEntity.id.uuid];
  const updatedListing = { ...oldListing, attributes: listingEntity.attributes };
  const ownListingEntities = {
    ...state.ownEntities.ownListing,
    [listingEntity.id.uuid]: updatedListing,
  };
  return {
    ...state,
    ownEntities: { ...state.ownEntities, ownListing: ownListingEntities },
  };
};

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );
const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const myPurchasesPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        queryInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        paginations: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    case VACATION_MODE_REQUEST:
      return {
        ...state,
        vacationInProgress: true,
      };
    case VACATION_MODE_SUCCESS:
      return {
        ...state,
        vacationInProgress: false,
      };
    case VACATION_MODE_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, vacationInProgress: false };

    case FETCH_OWN_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_OWN_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultDraftIds: resultIds(payload.data),
        pagination: payload.data.meta,
        queryInProgress: false,
        blogDatas: payload.blogData,
      };
    case FETCH_OWN_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    case OPEN_LISTING_REQUEST:
      return {
        ...state,
        openingListing: payload.listingId,
        openingListingError: null,
      };
    case OPEN_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        openingListing: null,
      };
    case OPEN_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        openingListing: null,
        openingListingError: {
          listingId: state.openingListing,
          error: payload,
        },
      };
    }

    case CLOSE_LISTING_REQUEST:
      return {
        ...state,
        closingListing: payload.listingId,
        closingListingError: null,
      };
    case CLOSE_LISTING_SUCCESS:
      return {
        ...updateListingAttributes(state, payload.data),
        closingListing: null,
      };
    case CLOSE_LISTING_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        closingListing: null,
        closingListingError: {
          listingId: state.closingListing,
          error: payload,
        },
      };
    }

    case ADD_OWN_ENTITIES:
      return merge(state, payload);

    default:
      return state;
  }
};

export default myPurchasesPageReducer;

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getOwnListingsById = (state, listingIds) => {
  const { ownEntities } = state.ManageListingsPage;
  const resources = listingIds.map(id => ({
    id,
    type: 'ownListing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(ownEntities, resources, throwIfNotFound);
};

// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)
export const addOwnEntities = sdkResponse => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});

export const openListingRequest = listingId => ({
  type: OPEN_LISTING_REQUEST,
  payload: { listingId },
});

export const openListingSuccess = response => ({
  type: OPEN_LISTING_SUCCESS,
  payload: response.data,
});

export const openListingError = e => ({
  type: OPEN_LISTING_ERROR,
  error: true,
  payload: e,
});

export const closeListingRequest = listingId => ({
  type: CLOSE_LISTING_REQUEST,
  payload: { listingId },
});

export const closeListingSuccess = response => ({
  type: CLOSE_LISTING_SUCCESS,
  payload: response.data,
});

export const closeListingError = e => ({
  type: CLOSE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryOwnListingsRequest = queryParams => ({
  type: FETCH_OWN_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryOwnListingsSuccess = (response, blogData) => ({
  type: FETCH_OWN_LISTINGS_SUCCESS,
  payload: { data: response.data, blogData },
});

export const queryOwnListingsError = e => ({
  type: FETCH_OWN_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const vacationModeRequest = () => ({
  type: VACATION_MODE_REQUEST,
});

export const vacationModeSuccess = () => ({
  type: VACATION_MODE_SUCCESS,
});

export const vacationModeError = e => ({
  type: VACATION_MODE_ERROR,
  error: true,
  payload: e,
});

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

// Throwing error for new (loadData may need that info)
export const queryOwnListings = queryParams => async (dispatch, getState, sdk) => {
  await dispatch(fetchCurrentUser());
  dispatch(queryListingsRequest(queryParams));
  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
        price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
      }
      : {};
  };
  const currentUser = getState().user.currentUser;
  const { perPage, price, ...rest } = queryParams;
  const priceMaybe = priceSearchParams(price);
  const params = {
    ...rest,
    per_page: perPage,
    authorId: currentUser && currentUser.id.uuid,
    ...priceMaybe,
    pub_isDelete: false,
  };
  try {
    const response = await getListings(params);
    if (response) {
      dispatch(addOwnEntities(response));
      dispatch(addMarketplaceEntities(response));
      dispatch(searchCustomListingsSuccess(response));
      dispatch(queryListingsSuccess(response));
      return response;
    }
  } catch (e) {
    dispatch(queryListingsError(storableError(e)));
    throw e;
  }
};
export const queryOwnDraftListings = queryParams => (dispatch, getState, sdk) => {
  dispatch(queryOwnListingsRequest(queryParams));
  const { perPage, ...rest } = queryParams;
  const params = { ...rest, per_page: perPage };

  return sdk.ownListings
    .query(params)
    .then(async response => {
      const { blogData } = await getBlogs({});
      dispatch(addOwnEntities(response));
      dispatch(queryOwnListingsSuccess(response, blogData));
      return response;
    })
    .catch(e => {
      dispatch(queryOwnListingsError(storableError(e)));
      throw e;
    });
};

export const closeListing = listingId => (dispatch, getState, sdk) => {
  dispatch(closeListingRequest(listingId));

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;
  const queryParam = {
    states: [PUBLISHED],
    perPage: RESULT_PAGE_SIZE,
    include: ['images', 'currentStock'],
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  };

  return sdk.ownListings
    .close({ id: listingId }, { expand: true })
    .then(response => {
      sdk.ownListings
        .update({ id: listingId, publicData: { isDelete: true } }, { expand: true })
        .then(async resp => {
          await dispatch(closeListingSuccess(resp));
          await dispatch(queryOwnListings(queryParam));
        });
      return response;
    })
    .catch(e => {
      dispatch(closeListingError(storableError(e)));
    });
};

export const closeOrOpenListing = values => async (dispatch, getState, sdk) => {
  try {
    const {
      data: { data = {} },
    } = await sdk.currentUser.updateProfile({
      publicData: {
        isVacationMode: values,
      },
    });
    dispatch(vacationModeRequest());
    if (data?.id) {
      const result = await closeOrOpenAllListings({ values, authorId: data.id });
      if (result) {
        const {
          aspectWidth = 1,
          aspectHeight = 1,
          variantPrefix = 'listing-card',
        } = config.listing;
        const aspectRatio = aspectHeight / aspectWidth;
        dispatch(
          queryOwnListings({
            page: result.page,
            include: ['images', 'currentStock'],
            'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
            ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
            ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
            'limit.images': 1,
          })
        );
        dispatch(vacationModeSuccess());
      }
    }
  } catch (error) {
    dispatch(vacationModeError());
  }
};

export const discardDraftListing = listingId => async (dispatch, getState, sdk) => {
  try {
    const result = await sdk.ownListings.discardDraft({
      id: listingId,
    });
    if (result) {
      const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
      const aspectRatio = aspectHeight / aspectWidth;
      const queryParam = {
        perPage: RESULT_PAGE_SIZE,
        include: ['images', 'currentStock'],
        states: [PUBLISHED, CLOSED],
        'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
        ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
        ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
        'limit.images': 1,
      };
      dispatch(queryOwnDraftListings(queryParam));
    }
  } catch (error) { }
};

export const updateListing = values => async (dispatch, getState, sdk) => {
  const { ids, discountPercentage, listingId, availability, quantity, currentStock } = values;
  try {
    if (ids) {
      const results = await Promise.all(
        ids.map(async id => {
          await sdk.ownListings.update({ id: new UUID(id), publicData: { discountPercentage } });
          await dispatch(showListing(id));
          return true;
        })
      );
      // Dispatch an action to update the state of your application
      // and inform the user of the success.

      return results;
    } else {
      const result = await sdk.ownListings.update({ id: listingId, publicData: { availability } });
      const results = await sdk.stock.compareAndSet(
        { listingId, oldTotal: currentStock, newTotal: quantity },
        { expand: true }
      );
      dispatch(addMarketplaceEntities(results));
      const data = (result || results) && (await dispatch(showListing(listingId.uuid)));
    }
  } catch (error) {
    // Dispatch an action to update the state of your application
    // and inform the user of the error.

    throw error;
  }
};

export const openListing = listingId => (dispatch, getState, sdk) => {
  dispatch(openListingRequest(listingId));

  return sdk.ownListings
    .open({ id: listingId }, { expand: true })
    .then(response => {
      dispatch(openListingSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(openListingError(storableError(e)));
    });
};

export const fetchAllTransactions = (search) => (dispatch, getState, sdk) => {

  dispatch(fetchOrdersOrSalesRequest());
  const { page = 1 } = parse(search);

  const apiQueryParams = {
    only: 'order',
    // lastTransitions: fetchOrderTransitions(),
    include: [
      'listing',
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'customer.publicData',
      'provider.publicData',
      'booking',
    ],
    'fields.transaction': [
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      // 'createdAt',
      'payinTotal',
      'payoutTotal',
      'lineItems',
      'metadata',
      'protectedData'
    ],
    // 'fields.listing': ['title','publicData','price'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    per_page: 10,
  };
  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search) => dispatch => {

  return Promise.all([
    // dispatch(queryOwnDraftListings(queryParam)),
    // dispatch(queryOwnListings(queryParam)),
    dispatch(fetchAllTransactions(search)),
    dispatch(stripeCustomer()),
  ]);
};
