import React, { Component } from 'react';
import { string, func, bool } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, SIZE_TYPE, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import {
  AspectRatioWrapper,
  Button,
  IconClose,
  IconCollection,
  NamedLink,
  ResponsiveImage,
} from '..';

import css from './SavedItemCard.module.css';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../../util/dataExtractor';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const SavedItemCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    onCancelCartItemNotification,
    onCartItemNotification,
  } = props;
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const { currentUser } = state.user;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const {quantity} = currentListing?.currentStock?.attributes || {} ; 
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const { size,customSize, listingVideoAndImagesUrl } = publicData || {};
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const { formattedPrice, priceTitle } = priceData(price, intl);
  const unitType = config.lineItemUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.perDay'
    : 'ListingCard.perUnit';

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;
  const { favouriteItems = [], saveLaterItems = [], bookmarks = [] } =
    currentUser?.attributes.profile.protectedData || {};
  const index = favouriteItems.indexOf(currentListing.id.uuid);
  const indexValue = saveLaterItems && saveLaterItems.findIndex(b => b.id == id);
  const ids = bookmarks && bookmarks.findIndex(b => b.id == id);
  const getsaveBatchId = saveLaterItems?.find(item => item.id === id)?.batchId;
  const cancelMail = getBatchId => {
    onCancelCartItemNotification({ batch_id: getBatchId });
  };

  const handleSaveForLater = async isBag => {
    if (indexValue > -1) {
      const titleSlug = createSlug(currentListing?.attributes.title);
      const timestamp = Math.floor((new Date().getTime() + 3600000) / 1000); // Current time + 1 hour
      const emailData = {
        buyerFirstName: currentUser?.id && getUserDetails(currentUser).firstName,
        sellerUserName: author?.id && getUserDetails(author).userName,
        listingTitle: currentListing?.attributes.title,
        listingPageUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/l/${titleSlug}/${currentListing?.id.uuid}`,
        chekoutPageUrl: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/bag`,
        userEmail: currentUser?.id && getUserDetails(currentUser).email,
        timestamp,
      };
      const { batch_id } = !isBag && (await onCartItemNotification(emailData));
      bookmarks.push({ id: currentListing?.id.uuid, batchId: batch_id });
      const addedBookmarks = Array.from(new Set(bookmarks));
      getsaveBatchId &&  await cancelMail(getsaveBatchId);
      saveLaterItems.splice(indexValue, 1);
      const addedSaveLaterItems = Array.from(new Set(saveLaterItems));
      const profile = {
        protectedData: isBag
          ? {
              saveLaterItems: addedSaveLaterItems,
            }
          : {
              bookmarks: addedBookmarks,
              saveLaterItems: addedSaveLaterItems,
            },
      };
      dispatch(updateProfile(profile));
    } else {
      saveLaterItems.push(currentListing.id.uuid);
      const addedSaveLaterItems = Array.from(new Set(saveLaterItems));
      const profile = {
        protectedData: {
          saveLaterItems: addedSaveLaterItems,
        },
      };
      dispatch(updateProfile(profile));
    }
  };

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <p
        onClick={e => {
          e.preventDefault();
          handleSaveForLater(true);
        }}
        className={css.removeBtn}
      >
        <span className={css.bookmark}>
          <IconCollection icon="IconCloseWhite" />
        </span>
      </p>
      <AspectRatioWrapper
        className={classNames(css.aspectRatioWrapper,quantity === 0 && css.itemSold)}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          handleFavouriteItems={() => {}}
          variants={variants}
          listingVideoAndImagesUrl={listingVideoAndImagesUrl}
          index={index}
          isCartPanel={true}
          sizes={renderSizes}
          currentStock={quantity}
        />
      </AspectRatioWrapper>
      <div className={css.info}>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          <div className={css.price}>
            <div className={css.priceValue} title={priceTitle}>
              <span className={css.priceText}>
                <b>{formattedPrice}</b> {price?.currency}
              </span>{' '}
              | <FormattedMessage id="OrderPanel.sizeText" />  {size === SIZE_TYPE ? customSize :size}
            </div>
            {config.listing.showUnitTypeTranslations ? (
              <div className={css.perUnit}>
                <FormattedMessage id={unitTranslationKey} />
              </div>
            ) : null}
          </div>
          <Button
            onClick={e => {
              e.preventDefault();
              handleSaveForLater(false);
            }}
            className={css.addtoBagBtn}
          >
            <FormattedMessage id="SavedItemCard.addToBag" />
          </Button>
        </div>
      </div>
    </NamedLink>
  );
};

SavedItemCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

SavedItemCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(SavedItemCardComponent);
