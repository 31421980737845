import React from 'react';
import PropTypes from 'prop-types';

const NoImageIcon = props => {
  const { className, manageListingPage } = props;

  return !manageListingPage ? (
    <svg
      className={className}
      width="48"
      height="49"
      viewBox="0 -1 48 49"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path strokeWidth="2" strokeLinejoin="round" d="M13.6 9.6L24 .8l10.4 8.8z" />
        <path strokeWidth="2" d="M.8 46.4h46.4V9.6H.8z" />
        <path strokeWidth="2" d="M5.6 41.6h36.8V14.4H5.6z" />
        <path
          d="M19.998 31.636l.835-.835a4.035 4.035 0 0 1 2.853-1.183h.693c1.07 0 2.097.426 2.853 1.183l.835.835"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M21.599 23.983a1.009 1.009 0 1 0 0 2.018 1.009 1.009 0 0 0 0-2.018M26.402 23.983a1.009 1.009 0 1 0 0 2.018 1.009 1.009 0 0 0 0-2.018" />
      </g>
    </svg>
  ) : (
    <svg
      className={className}
      width="84"
      height="73"
      viewBox="0 0 84 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M63 13.0503C63 12.3434 63.2598 11.7317 63.7793 11.2151C64.2988 10.6985 64.9141 10.4402 65.625 10.4402C66.3359 10.4402 66.9512 10.6985 67.4707 11.2151C67.9902 11.7317 68.25 12.3434 68.25 13.0503C68.25 13.7572 67.9902 14.3689 67.4707 14.8855C66.9512 15.402 66.3359 15.6603 65.625 15.6603C64.9141 15.6603 64.2988 15.402 63.7793 14.8855C63.2598 14.3689 63 13.7572 63 13.0503ZM5.25 57.4212H35.2734L33.9609 62.6413H0V0H84V23.6536C83.2344 23.0555 82.4141 22.5525 81.5391 22.1447C80.6641 21.7369 79.7344 21.4242 78.75 21.2067V5.22011H5.25V32.8296L23.625 14.6L39.375 30.2603L49.875 19.8201L60.5801 30.4235L56.8477 34.1346L49.875 27.1609L43.0664 33.9307L50.0801 40.8637L46.3477 44.5749L23.625 21.9408L5.25 40.252V57.4212ZM78.75 50.162L84 44.9419V62.6413H66.1992L71.4492 57.4212H78.75V50.162ZM84 34.1754C84 35.2358 83.7949 36.2689 83.3848 37.2749C82.9746 38.2808 82.3867 39.1644 81.6211 39.9257L52.2129 69.1665L36.75 73L40.6055 57.6659L70.0137 28.4251C70.7793 27.6639 71.668 27.1065 72.6797 26.7531C73.6914 26.3996 74.7305 26.2229 75.7969 26.2229C76.9453 26.2229 78.0117 26.4132 78.9961 26.7939C79.9805 27.1745 80.8555 27.7318 81.6211 28.4659C82.3867 29.2 82.9609 30.0292 83.3438 30.9536C83.7266 31.878 83.9453 32.952 84 34.1754ZM77.8887 36.2553C78.4629 35.6844 78.75 34.9911 78.75 34.1754C78.75 33.3054 78.4766 32.6257 77.9297 32.1363C77.3828 31.6469 76.6719 31.3886 75.7969 31.3615C75.4141 31.3615 75.0449 31.4158 74.6895 31.5246C74.334 31.6333 74.0195 31.8237 73.7461 32.0955L45.3633 60.3168L43.9688 65.8223L49.5059 64.4358L77.8887 36.2553Z"
        fill="#3065A1"
      />
    </svg>
  );
};

const { string } = PropTypes;

NoImageIcon.defaultProps = {
  className: null,
};

NoImageIcon.propTypes = {
  className: string,
};

export default NoImageIcon;
