import React, { useEffect, useState } from 'react';
import { propTypes, transactions } from '../../../util/types';
import { oneOf } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import UserDisplayName from '../../UserDisplayName/UserDisplayName';
import {
  TRANSITION_COUNTER_OFFER_AUTO_EXPIRE,
  TRANSITION_COUNTER_OFFER_COUNTERED_BY_CUSTOMER,
  TRANSITION_COUNTER_OFFER_DECLINED_BY_CUSTOMER,
  TRANSITION_MAKE_AN_OFFER_BY_CUSTOMER,
  TRANSITION_OFFER_AUTO_EXPIRE,
  TRANSITION_OFFER_COUNTERED_BY_PROVIDER,
  TRANSITION_OFFER_DECLINED_BY_PROVIDER,
  txIsPurchased,
} from '../../../util/transaction';
import { formatDateIntoPartials } from '../../../util/dates';
import Avatar from '../../Avatar/Avatar';
import NamedLink from '../../NamedLink/NamedLink';
import config from '../../../config';
import { compose } from 'redux';
import classNames from 'classnames';
import css from './TopbarDesktop.module.css';
import AspectRatioWrapper from '../../AspectRatioWrapper/AspectRatioWrapper';
import { setActiveListing } from '../../../containers/SearchPage/SearchPage.duck';
import { LazyImage } from '../../ListingCard/ListingCard';
import moment from 'moment';
import IconCollection from '../../IconCollection/IconCollection';
import { updateTransactionMetadata } from '../../../util/api';
import { useDispatch } from 'react-redux';
import { fetchTransaction } from '../../../containers/TransactionPage/TransactionPage.duck';

const messages = {
  [TRANSITION_MAKE_AN_OFFER_BY_CUSTOMER]: 'OfferNotifications.remainHours',
  [TRANSITION_OFFER_COUNTERED_BY_PROVIDER]: 'OfferNotifications.remainHours',
  [TRANSITION_COUNTER_OFFER_COUNTERED_BY_CUSTOMER]: 'OfferNotifications.remainHours',
  [TRANSITION_OFFER_AUTO_EXPIRE]: 'OfferNotifications.expire',
  [TRANSITION_COUNTER_OFFER_AUTO_EXPIRE]: 'OfferNotifications.expire',
  [TRANSITION_OFFER_DECLINED_BY_PROVIDER]: 'OfferNotifications.decline',
  [TRANSITION_COUNTER_OFFER_DECLINED_BY_CUSTOMER]: 'OfferNotifications.decline',
};

const OfferNotificationsComponent = props => {
  const [countdown, setCountdown] = useState(0);
  const dispatch = useDispatch();

  const { unitType, type, tx, intl, stateData, isAuthenticated } = props;
  const { customer, provider, listing } = tx;
  const isOrder = type === 'order';

  const unitPurchase = tx.attributes?.lineItems?.find(
    item => item.code === unitType && !item.reversal
  );
  const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
  const { isCustomerRead, isProviderRead } = tx?.attributes?.metadata || {};

  const otherUser = isOrder ? provider : customer;
  const otherUserDisplayName = <UserDisplayName user={otherUser} intl={intl} />;
  const {userName} = otherUser.attributes.profile.publicData ||{};
  const isOtherUserBanned = otherUser.attributes.banned;

  const isSaleNotification = !isOrder && txIsPurchased(tx);
  const rowNotificationDot = isSaleNotification ? <div className={css.notificationDot} /> : null;
  const lastTransitionedAt = formatDateIntoPartials(tx.attributes.lastTransitionedAt, intl);
  const lastTransition = tx.attributes.lastTransition;
  const date = moment(tx.attributes.lastTransitionedAt)
    .add(24, 'hours')
    .toDate();

  const currentListing = listing;
  const { title = '', price, publicData } = currentListing.attributes;
  const { size, listingVideoAndImagesUrl } = publicData || {};
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];
  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;
  const txRole = isOrder ? 'customer' : 'provider';
  const linkClasses = classNames(css.itemLink, {
    [css.bannedUserLink]: isOtherUserBanned,
  });
  const isMapVariant = true;
  const cardRenderSizes = isMapVariant => {
    if (isMapVariant) {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 767px) 100vw',
        `(max-width: 1023px) ${panelMediumWidth}vw`,
        `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
        `${panelLargeWidth / 3}vw`,
      ].join(', ');
    } else {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 549px) 100vw',
        '(max-width: 767px) 50vw',
        `(max-width: 1439px) 26vw`,
        `(max-width: 1920px) 18vw`,
        `14vw`,
      ].join(', ');
    }
  };

  useEffect(() => {
    function updateCountdown() {
      const endDate = new Date(date);
      const currentTime = new Date();
      const timeDifference = endDate - currentTime;
      const hoursLeft = Math.ceil(timeDifference / (1000 * 60 * 60)); // 1 hour = 3600000 milliseconds
      setCountdown(hoursLeft);
    }
    // Initial update
    updateCountdown();
    // Update the countdown every minute (you can adjust this interval)
    const intervalId = setInterval(updateCountdown, 60000); // 1 minute = 60000 milliseconds
    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const messageKey = messages[lastTransition];
  const message = messageKey ? <FormattedMessage id={messageKey} values={{ countdown }} /> : null;

  return (
    <div className={css.item}>
      <div className={css.itemAvatar}>
        <Avatar user={otherUser} />
      </div>
      <NamedLink
        className={transactions.includes(lastTransition) ? css.pointerEvents : linkClasses}
        name={isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage'}
        params={{ id: tx.id.uuid }}
      >
        <div
          onClick={async () => {
            const result = await updateTransactionMetadata({
              id: tx.id,
              isCustomerRead: isOrder ? true : isCustomerRead,
              isProviderRead: isOrder ? isProviderRead : true,
            });
            if (result) {
              dispatch(fetchTransaction(tx.id, txRole));
            }
          }}
          className={css.offerItems}
        >
          <div>
            <div className={css.itemInfo}>
              <div className={css.itemUsername}>
                {userName} {stateData.state}
              </div>
            </div>
            <div className={css.itemState}>
              <div
                className={classNames(
                  css.lastTransitionedAt,
                  stateData.lastTransitionedAtClassName
                )}
                title={lastTransitionedAt.dateAndTime}
              >
                {message}
              </div>
            </div>
          </div>
          <AspectRatioWrapper
            className={css.aspectRatioWrapper}
            width={aspectWidth}
            height={aspectHeight}
            {...setActivePropsMaybe}
          >
            <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              listingVideoAndImagesUrl={listingVideoAndImagesUrl}
              handleFavouriteItems={() => {}}
              variants={variants}
              isAuthenticated={isAuthenticated}
              index={0}
              isListingCards={false}
              sizes={cardRenderSizes(isMapVariant)}
            />
          </AspectRatioWrapper>
        </div>
      </NamedLink>
    </div>
  );
};
OfferNotificationsComponent.defaultProps = {
  unitType: config.lineItemUnitType,
  currentUser: null,
  currentUserHasOrders: null,
  fetchOrdersOrSalesError: null,
  pagination: null,
  providerNotificationCount: 0,
  sendVerificationEmailError: null,
};

OfferNotificationsComponent.propTypes = {
  unitType: propTypes.lineItemUnitType.isRequired,
  type: oneOf(['order', 'sale']).isRequired,
  tx: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

const OfferNotifications = compose(injectIntl)(OfferNotificationsComponent);

export default OfferNotifications;
