import config from '../../config';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { searchCustomListingsSuccess } from '../SearchPage/SearchPage.duck';
import { fetchAuthorSalesData, followUser, getUserFollowCounts, unfollowUser } from '../../util/api';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { fetchTransitions } from '../../util/dataExtractor';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/ProfilePage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/ProfilePage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/ProfilePage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/ProfilePage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/ProfilePage/QUERY_LISTINGS_ERROR';

export const QUERY_REVIEWS_REQUEST = 'app/ProfilePage/QUERY_REVIEWS_REQUEST';
export const QUERY_REVIEWS_SUCCESS = 'app/ProfilePage/QUERY_REVIEWS_SUCCESS';
export const QUERY_REVIEWS_ERROR = 'app/ProfilePage/QUERY_REVIEWS_ERROR';

export const AUTHOR_FOLLOWER_DETAILS_REQUEST =
  'app/ProfilePage/GET_AUTHOR_FOLLOWERS_DETAILS_REQUEST';
export const AUTHOR_FOLLOWER_DETAILS_SUCCESS = 'app/ProfilePage/AUTHOR_FOLLOWER_DETAILS_SUCCESS';
export const AUTHOR_FOLLOWER_DETAILS_ERROR = 'app/ProfilePage/GET_AUTHOR_FOLLOWERS_DETAILS_ERROR';

export const GET_CURRENT_USER_FOLLOWERS_DETAILS =
  'app/ProfilePage/GET_CURRENT_USER_FOLLOWERS_DETAILS';

export const GET_AUTHOR_SALE_DATA =
  'app/ProfilePage/GET_AUTHOR_SALE_DATA';

// ================ Reducer ================ //

const initialState = {
  userId: null,
  userListingRefs: [],
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
  authorFollowerDetails: {},
  currentUserFollowerDetails: {},
  followRequestInProgress: false,
  followRequestError: null,
  queryListingsInProgress: false,
  otherUserData: null,
  pagination:null,
  saleTransactionLength:0
};

export default function profilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case SHOW_USER_REQUEST:
      return { ...state, userShowError: null, userId: payload.userId };
    case SHOW_USER_SUCCESS:
      return { ...state, otherUserData: payload.otherUserData };
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload };
      case GET_AUTHOR_SALE_DATA:
        return { ...state, saleTransactionLength: payload.data };
    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,

        // Empty listings only when user id changes
        userListingRefs: payload.userId === state.userId ? state.userListingRefs : [],
        queryListingsInProgress: true,
        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return { ...state, userListingRefs: payload.listingRefs, queryListingsInProgress: false ,pagination:payload.data.meta};
    case QUERY_LISTINGS_ERROR:
      return {
        ...state,
        userListingRefs: [],
        queryListingsError: payload,
        queryListingsInProgress: false,
      };
    case QUERY_REVIEWS_REQUEST:
      return { ...state, queryReviewsError: null };
    case QUERY_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case QUERY_REVIEWS_ERROR:
      return { ...state, reviews: [], queryReviewsError: payload };
    case AUTHOR_FOLLOWER_DETAILS_REQUEST:
      return { ...state, followRequestInProgress: true, followRequestError: null };
    case AUTHOR_FOLLOWER_DETAILS_SUCCESS:
      return {
        ...state,
        authorFollowerDetails: payload,
        followRequestInProgress: false,
        followRequestError: null,
      };
    case AUTHOR_FOLLOWER_DETAILS_ERROR:
      return { ...state, followRequestInProgress: false, followRequestError: payload };

    case GET_CURRENT_USER_FOLLOWERS_DETAILS:
      return { ...state, currentUserFollowerDetails: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
});

export const showUserSuccess = otherUserData => ({
  type: SHOW_USER_SUCCESS,
  payload: { otherUserData },
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const getAuthorSaleData = data => ({
  type: GET_AUTHOR_SALE_DATA,
  payload: { data },
});

export const queryListingsRequest = userId => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { userId },
});

export const queryListingsSuccess = (listingRefs,response) => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listingRefs,data: response.data },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryReviewsRequest = () => ({
  type: QUERY_REVIEWS_REQUEST,
});
export const authorFollowRequestRequest = data => ({
  type: AUTHOR_FOLLOWER_DETAILS_REQUEST,
  payload: data,
});
export const authorFollowRequestSuccess = data => ({
  type: AUTHOR_FOLLOWER_DETAILS_SUCCESS,
  payload: data,
});
export const authorFollowRequestError = e => ({
  type: AUTHOR_FOLLOWER_DETAILS_ERROR,
  error: true,
  payload: e,
});
export const getCurrentUserFollowCountsDetails = data => ({
  type: GET_CURRENT_USER_FOLLOWERS_DETAILS,
  payload: data,
});

export const queryReviewsSuccess = reviews => ({
  type: QUERY_REVIEWS_SUCCESS,
  payload: reviews,
});

export const queryReviewsError = e => ({
  type: QUERY_REVIEWS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryUserListings = (userId, search) => (dispatch, getState, sdk) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  // const queryParams = parse(search);
  const page = queryParams.page || 1;
  dispatch(queryListingsRequest(userId));

  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
        }
      : {};
  };
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;
  const priceMaybe = priceSearchParams(queryParams.price);
  return sdk.listings
    .query({
      ...queryParams,
      ...priceMaybe,
      author_id: userId,
      page,
      per_page: 40,
      include: ['author', 'images','currentStock'],
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    })
    .then(response => {
      // Pick only the id and type properties from the response listings
      const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));
      dispatch(addMarketplaceEntities(response));
      dispatch(searchCustomListingsSuccess(response));
      dispatch(queryListingsSuccess(listingRefs,response));
      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const queryUserReviews = userId => (dispatch, getState, sdk) => {
  sdk.reviews
    .query({
      subject_id: userId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(queryReviewsSuccess(reviews));
    })
    .catch(e => dispatch(queryReviewsError(e)));
};

export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(showUserSuccess(response.data.data));
      return response;
    })
    .catch(e => dispatch(showUserError(storableError(e))));
};

export const followStore = params => async (dispatch, getState, sdk) => {
  dispatch(authorFollowRequestRequest());
  try {
    await dispatch(fetchCurrentUser());
    const { currentUser = {} } = getState().user;
    const response = await followUser(params);
    if (response) {
      dispatch(authorFollowRequestSuccess(response.authorFollowerDetails));
      if (currentUser.id) {
        dispatch(getCurrentUserFollowCountsDetails(response.currentUserFollowerDetails));
      }
    }
  } catch (e) {
    dispatch(authorFollowRequestSuccess(storableError(e)));
  }
};
export const unfollowStore = params => async (dispatch, getState, sdk) => {
  dispatch(authorFollowRequestRequest());

  try {
    await dispatch(fetchCurrentUser());
    // const { currentUser = {} } = getState().user;
    const { authorId, currentUserId } = params;
    const response = await unfollowUser({ authorId, currentUserId });
    if (response) {
      dispatch(authorFollowRequestSuccess(response.authorFollowerDetails));
      if (currentUserId) {
        dispatch(getCurrentUserFollowCountsDetails(response.currentUserFollowerDetails));
      }
    }
  } catch (e) {
    dispatch(authorFollowRequestSuccess(storableError(e)));
  }
};

export const authorSalesData = params => async (dispatch, getState, sdk) => {
  const { userId } = params;
  try {
    const result = await fetchAuthorSalesData({ userId });
    const saleTransactions = result.saleTransactions.filter((st) =>fetchTransitions().includes(st.attributes.lastTransition));
    dispatch(getAuthorSaleData(saleTransactions.length));
  } catch (error) {
  }
};

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  try {
    // Extract the user ID from params
    const userId = new UUID(params.id);

    // Clear state so that previously loaded data is not visible
    // in case this page load fails.
    dispatch(setInitialState());

    // Use Promise.all to fetch multiple pieces of data concurrently
    const actions = [
      dispatch(fetchCurrentUser()),
      dispatch(showUser(userId)),
      dispatch(queryUserListings(userId, search)),
      dispatch(queryUserReviews(userId)),
      dispatch(authorSalesData({userId}))
    ];

    await Promise.all(actions);
    // Fetch author's follower details
    const { currentUser = {} } = getState().user || {};
    const authorFollowerDetails = await getUserFollowCounts(params.id);

    if (authorFollowerDetails) {
      dispatch(authorFollowRequestSuccess(authorFollowerDetails));
    }
    if (currentUser.id) {
      const currentUserFollowerDetails = await getUserFollowCounts(currentUser.id.uuid);
      dispatch(getCurrentUserFollowCountsDetails(currentUserFollowerDetails));
    }
  } catch (error) {
    return;
    // console.error('Error loading data:', error);
    // Handle error as needed
  }
};
