import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal, TabNav } from '../../components';

import css from './UserNav.module.css';
import {
  CLOSED,
  DISCOUNT,
  DRAFT,
  MANAGE_LISTING_DISCOUNT_PAGE,
  MANAGE_LISTING_DRAFT_PAGE,
  MANAGE_LISTING_OFFERS_PAGE,
  MANAGE_LISTING_POST_PAGE,
  MANAGE_LISTING_SOLD_PAGE,
  OFFERS,
  PUBLISHED,
  SOLD,
} from '../../util/types';

const UserNav = props => {
  const {
    className,
    rootClassName,
    selectedPageName,
    tabVertical,
    listings,
    params,
    draftListings,
    currentUserSaleNotificationCount,
    totalItems
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const tabs = [
    {
      text: <FormattedMessage id="ManageListingsPage.yourListings" />,
      selected: selectedPageName === MANAGE_LISTING_POST_PAGE,
      linkProps: {
        name: MANAGE_LISTING_POST_PAGE,
        params: { state: PUBLISHED },
      },
    },
    {
      text: <FormattedMessage id="ManageListingsPage.profileSettings" />,
      selected: selectedPageName === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
    },
    {
      text: <FormattedMessage id="ManageListingsPage.accountSettings" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(selectedPageName),
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
  ];

  const myListingPageTabs = [
    {
      text: (
        <FormattedMessage
          id="ManageListingsPage.postedListings"
          values={{
            postedCount: totalItems ? totalItems :
              listings?.length &&
              listings.filter(
                e => e.attributes.state === PUBLISHED || e.attributes.state === CLOSED
              ).length,
          }}
        />
      ),
      selected: selectedPageName === MANAGE_LISTING_POST_PAGE,
      linkProps: {
        name: MANAGE_LISTING_POST_PAGE,
        params: { state: PUBLISHED },
      },
    },
    {
      text: (
        <FormattedMessage
          id="ManageListingsPage.soldListings"
          values={{
            postedCount: currentUserSaleNotificationCount,
          }}
        />
      ),
      selected: selectedPageName === MANAGE_LISTING_SOLD_PAGE,
      linkProps: {
        name: MANAGE_LISTING_SOLD_PAGE,
        params: { state: SOLD },
      },
    },
    {
      text: (
        <FormattedMessage
          id="ManageListingsPage.drafsListings"
          values={{
            drafsCount:
              draftListings?.length &&
              draftListings.filter(e => e.attributes.state === DRAFT).length,
          }}
        />
      ),
      selected: selectedPageName === MANAGE_LISTING_DRAFT_PAGE,

      linkProps: {
        name: MANAGE_LISTING_DRAFT_PAGE,
        params: { state: DRAFT },
      },
    },
    // {
    //   text: <FormattedMessage id="ManageListingsPage.sellerDiscount" />,
    //   selected: selectedPageName === MANAGE_LISTING_DISCOUNT_PAGE,
    //   disabled: false,
    //   linkProps: {
    //     name: MANAGE_LISTING_DISCOUNT_PAGE,
    //     params: { state: DISCOUNT },
    //   },
    // },
    {
      text: (
        <FormattedMessage
          id="ManageListingsPage.Offers"
          values={{
            offersCount:
              listings?.length &&
              listings.filter(
                e => e.attributes.state === PUBLISHED || e.attributes.state === CLOSED
              ).length,
          }}
        />
      ),
      selected: selectedPageName === MANAGE_LISTING_OFFERS_PAGE,
      disabled: false,
      linkProps: {
        name: MANAGE_LISTING_OFFERS_PAGE,
        params: { state: OFFERS },
      },
    },
  ];

  return tabVertical ? (
    <TabNav
      className={classes}
      tabRootClassName={css.tab}
      tabs={tabVertical ? myListingPageTabs : tabs}
      skin="dark"
    />
  ) : (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  selectedPageName: string.isRequired,
};

export default UserNav;
