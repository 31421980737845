import React, { useState, useEffect } from 'react';
import { any, bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import {
  ALL_GENDER,
  CLOTHING,
  LINE_ITEM_UNITS,
  PUBLISHED,
  SNEAKERS,
  SOLD,
  STREETWEAR,
  VINTAGE,
  propTypes,
} from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  IconCollection,
  IconSpinner,
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import css from './TopbarDesktop.module.css';
import { InboxItem, toTxItem, txState } from '../../../containers/InboxPage/InboxPage';
import OfferNotifications from './OfferNotifications';
import { getUserDetails } from '../../../util/dataExtractor';
import StarRatings from 'react-star-ratings';
import { listingStores } from '../../../config/marketplace-custom-config';
import { useSelector } from 'react-redux';
const calculateAverage = reviews => {
  if (reviews?.length === 0) {
    return 0;
  }
  const sum =
    reviews &&
    reviews?.reduce((accumulator, currentValue) => accumulator + currentValue.attributes.rating, 0);
  const average = sum / reviews?.length;
  return average;
};

const TopbarDesktop = props => {
  const state = useSelector(state => state);
  const { earningTransactions,userOrderCount } = state.user;
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    bookmarks,
    lb,
    activeCategory,
    fetchInProgress,
    offerTransactions,
    reviews,
    currentUserFollowerDetails,
    transactions,
    currentUserSaleLength,
  } = props;

  const [mounted, setMounted] = useState(false);
  const [isAccountOpen, setIsAccountOpen] = useState(false);
  const userName = currentUser?.id && getUserDetails(currentUser)?.userName;
  const { followers = [], following = [] } = currentUserFollowerDetails || {};
  useEffect(() => {
    setMounted(true);
  }, []);

  // Calculate total amount using reduce
  const totalAmount = earningTransactions
    .filter(e => e.attributes.payoutTotal)
    .reduce((accumulator, currentValue) => {
      return parseInt(accumulator) + parseInt(currentValue?.attributes?.payoutTotal?.amount);
    }, 0);

  const toTxItem = tx => {
    const type = currentUser?.id?.uuid === tx?.provider?.id?.uuid ? 'sale' : 'order';
    const stateData = txState(intl, tx, type);

    // Render InboxItem only if the latest transition of the transaction is handled in the `txState` function.
    return stateData ? (
      <li key={tx.id.uuid} className={css.listItem}>
        <OfferNotifications
          unitType={LINE_ITEM_UNITS}
          type={type}
          tx={tx}
          intl={intl}
          stateData={stateData}
          isAuthenticated={isAuthenticated}
        />
      </li>
    ) : null;
  };

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(
    rootClassName || css.root,
    className,
    activeCategory === CLOTHING
      ? css.clothing
      : activeCategory === SNEAKERS
        ? css.sneakers
        : activeCategory === STREETWEAR
          ? css.streetwear
          : activeCategory === VINTAGE
            ? css.vintage
            : null
  );

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const readCount = offerTransactions.find(e => e?.provider?.id?.uuid === currentUser?.id?.uuid)
    ? offerTransactions.length -
    offerTransactions.filter(e => e?.attributes?.metadata?.isProviderRead).length
    : offerTransactions.length -
    offerTransactions.filter(e => e?.attributes?.metadata?.isCustomerRead).length;

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
        <IconCollection icon="IconArrowDownSM" />
      </MenuLabel>
      {isAccountOpen ? (
        <MenuContent className={css.profileMenuContent}>
          <MenuItem key="MyPurchasesPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('MyPurchasesPage'))}
              name="MyPurchasesPage"
              params={{ sort: 'all' }}
            >
              <span className={classNames(css.menuIcon)}>
                <IconCollection icon="iconMenuShirt" />
              </span>
              <FormattedMessage id="TopbarDesktop.myPurchaseLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="ManageListingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
              name="ManageListingsPostPage"
              params={{ state: PUBLISHED }}
            >
              <span className={classNames(css.menuIcon)}>
                <IconCollection icon="iconMenuPencil" />
              </span>
              <FormattedMessage id="TopbarDesktop.yourListingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="ProfileSettingsPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('ProfileSettingsPage')
              )}
              name="ProfileSettingsPage"
            >
              <span className={classNames(css.menuIcon)}>
                <IconCollection icon="iconMenuSecurity" />
              </span>
              <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="AccountSettingsDefaultPage">
            <div
              onClick={() => {
                setIsAccountOpen(false);
              }}
              className={classNames(css.accountSettingDiv)}
            >
              <div className={classNames(css.yourListingsLink)}>
                <span className={classNames(css.menuIcon)}>
                  <IconCollection icon="iconMenuMan" />
                </span>
                <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
              </div>
              <IconCollection icon="iconArrowUpWhite" />
            </div>
          </MenuItem>
          <MenuItem key="ProfileSettingsPages">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                css.accountSettingItem,
                currentPageClass('ProfileSettingsPage')
              )}
              name="ProfileSettingsPage"
            >
              <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            </NamedLink>
          </MenuItem>

          <MenuItem key="ItemPreferencesPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                css.accountSettingItem,
                currentPageClass('ItemPreferencesPage')
              )}
              name="ItemPreferencesPage"
            >
              <FormattedMessage id="TopbarDesktop.itemPrefernceLink" />
            </NamedLink>
          </MenuItem>

          <MenuItem key="NotificationsPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                css.accountSettingItem,
                currentPageClass('NotificationsPage')
              )}
              name="NotificationsPage"
            >
              <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.notificationTabTitle" />
            </NamedLink>
          </MenuItem>

          <MenuItem key="PaymentMethodsPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                css.accountSettingItem,
                currentPageClass('PaymentMethodsPage')
              )}
              name="PaymentMethodsPage"
            >
              <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentMethodsTabTitle" />
            </NamedLink>
          </MenuItem>

          <MenuItem key="ShippingAddressDetailPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                css.accountSettingItem,
                currentPageClass('ShippingAddressDetailPage')
              )}
              name="ShippingAddressDetailPage"
            >
              <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.addressTabTitle" />
            </NamedLink>
          </MenuItem>

          <MenuItem key="StripePayoutPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                css.accountSettingItem,
                currentPageClass('StripePayoutPage')
              )}
              name="StripePayoutPage"
            >
              <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentsTabTitle" />
            </NamedLink>
          </MenuItem>

          <MenuItem key="DeleteAccountPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                css.accountSettingItem,
                currentPageClass('DeleteAccountPage')
              )}
              name="DeleteAccountPage"
            >
              <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.deleteAccountTabTitle" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="logout">
            <div className={classNames(css.accountSettingDiv)} onClick={onLogout}>
              <div className={classNames(css.yourListingsLink)}>
                <span className={classNames(css.menuIcon)}>
                  <IconCollection icon="iconLogOut" />
                </span>
                <FormattedMessage id="TopbarDesktop.logout" />
              </div>
            </div>
          </MenuItem>
          <MenuItem key="ReviewSectionPage">
            <div className={classNames(css.profileSettingDiv)}>
              <div className={css.profileSettingsContent}>
                <div className={css.userName}>{userName}</div>

                <div className={css.starRatings}>
                  <StarRatings
                    svgIconViewBox="0 0 40 37"
                    svgIconPath="M20 0L26.113 11.5862L39.0211 13.8197L29.891 23.2138L31.7557 36.1803L20 30.4L8.2443 36.1803L10.109 23.2138L0.97887 13.8197L13.887 11.5862L20 0Z"
                    starRatedColor="#ffb802"
                    // starEmptyColor="#ffffff"
                    rating={calculateAverage(reviews)}
                    starDimension="25px"
                    starSpacing="2px"
                  />
                  <span
                    className={css.reviewText}
                    onClick={() => this.setState({ isReviewOpen: true })}
                  >
                    (
                    <FormattedMessage id="ProfilePage.reviews" values={{ count: reviews.length }} />
                    )
                  </span>
                </div>
                <div className={css.salesAndFollowersInfo}>
                  <span>{userOrderCount.length} Purchases |</span>
                  <span style={{ cursor: 'pointer' }}>
                    {followers?.length === 1 ? (
                      <>
                        <FormattedMessage
                          id="ProfilePage.follower"
                          values={{ count: followers?.length }}
                        />{' '}
                        &nbsp;
                      </>
                    ) : (
                      <>
                        {' '}
                        &nbsp;{' '}
                        <FormattedMessage
                          id="ProfilePage.followers"
                          values={{ count: followers?.length }}
                        />
                      </>
                    )}
                  </span>
                  &nbsp;|&nbsp;
                  <span style={{ cursor: 'pointer' }}>
                    <FormattedMessage
                      id="ProfilePage.following"
                      values={{ count: following?.length }}
                    />
                  </span>
                </div>
                <div>
                  {currentUserHasListings ? (
                    <div>
                      <div className={classNames(css.buttonSeller, css.buttonSeller2)}>
                        <span className={css.availableEarnings}>
                          <FormattedMessage id="TopbarDesktop.availableEarning" /> &nbsp;
                          {totalAmount ? (
                            <span className={css.totalAmount}>${totalAmount / 100}USD</span>
                          ) : (
                            <span>None Available Yet</span>
                          )}
                        </span>
                        {totalAmount && (
                          <span className={css.arrowRight}>
                            <IconCollection icon="iconArrowDownWhite" />
                          </span>
                        )}
                      </div>
                      <div className={css.storeItems}>
                        <div>
                          <span>Sold Items ({currentUserSaleLength})</span>
                        </div>
                        {!!currentUser && (
                          <div>
                            <NamedLink name="ProfilePage" params={{ id: currentUser.id.uuid }}>
                              <FormattedMessage id="TopbarDesktop.viewStore" />
                            </NamedLink>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className={css.buttonSeller}>
                        <NamedLink name="NewListingPage">
                          <span>
                            <FormattedMessage id="TopbarDesktop.becomeSeller" />
                          </span>
                          <span className={css.arrowRight}>
                            <IconCollection icon="iconArrowDownWhite" />
                          </span>
                        </NamedLink>
                      </div>
                      <div className={css.storeItems}>
                        {!!currentUser && (
                          <div>
                            <NamedLink name="ProfilePage" params={{ id: currentUser.id.uuid }}>
                              <FormattedMessage id="TopbarDesktop.viewStore" />
                            </NamedLink>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className={css.category}>
                {listingStores.map(store => {
                  return (
                    <div key={store?.key}>
                      <div className={css.iconWrapper}>
                        <NamedLink
                          name="LandingPageListingStore"
                          params={{ type: store.key, gender: ALL_GENDER }}
                          className={css.icon}
                        >
                          <img src={store.icon} alt="icon" />
                        </NamedLink>
                      </div>
                      <span>{store.label}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </MenuItem>
        </MenuContent>
      ) : (
        <MenuContent className={css.profileMenuContent}>
          <MenuItem key="MyPurchasesPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('MyPurchasesPage'))}
              name="MyPurchasesPage"
              params={{ sort: 'all' }}
            >
              <span className={classNames(css.menuIcon)}>
                <IconCollection icon="iconMenuShirt" />
              </span>
              <FormattedMessage id="TopbarDesktop.myPurchaseLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="ManageListingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
              name="ManageListingsPostPage"
              params={{ state: PUBLISHED }}
            >
              <span className={classNames(css.menuIcon)}>
                <IconCollection icon="iconMenuPencil" />
              </span>
              <FormattedMessage id="TopbarDesktop.yourListingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="ProfileSettingsPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('ProfileSettingsPage')
              )}
              name="ProfileSettingsPage"
            >
              <span className={classNames(css.menuIcon)}>
                <IconCollection icon="iconMenuSecurity" />
              </span>
              <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="AccountSettingsPage">
            <div
              onClick={() => {
                setIsAccountOpen(true);
              }}
              className={classNames(css.accountSettingDiv)}
            >
              <div className={classNames(css.yourListingsLink)}>
                <span className={classNames(css.menuIcon)}>
                  <IconCollection icon="iconMenuMan" />
                </span>
                <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
              </div>
              <IconCollection icon="iconArrowDownWhite" />
            </div>
          </MenuItem>
          <MenuItem key="logout">
            <div className={classNames(css.accountSettingDiv)} onClick={onLogout}>
              <div className={classNames(css.yourListingsLink)}>
                <span className={classNames(css.menuIcon)}>
                  <IconCollection icon="iconLogOut" />
                </span>
                <FormattedMessage id="TopbarDesktop.logout" />
              </div>
            </div>
          </MenuItem>

          <MenuItem key="ReviewSectionPage">
            <div className={classNames(css.profileSettingDiv)}>
              <div className={css.profileSettingsContent}>
                <div className={css.userName}>{userName}</div>

                <div className={css.starRatings}>
                  <StarRatings
                    svgIconViewBox="0 0 40 37"
                    svgIconPath="M20 0L26.113 11.5862L39.0211 13.8197L29.891 23.2138L31.7557 36.1803L20 30.4L8.2443 36.1803L10.109 23.2138L0.97887 13.8197L13.887 11.5862L20 0Z"
                    starRatedColor="#ffb802"
                    // starEmptyColor="#ffffff"
                    rating={calculateAverage(reviews)}
                    starDimension="25px"
                    starSpacing="2px"
                  />
                  <span
                    className={css.reviewText}
                    onClick={() => this.setState({ isReviewOpen: true })}
                  >
                    (
                    <FormattedMessage id="ProfilePage.reviews" values={{ count: reviews.length }} />
                    )
                  </span>
                </div>
                <div className={css.salesAndFollowersInfo}>
                  <NamedLink
                    className={classNames(
                      css.purchasePageLink,
                      currentPageClass('MyPurchasesPage')
                    )}
                    name="MyPurchasesPage"
                    params={{ sort: 'all' }}
                  >
                    {userOrderCount.length} Purchases |
                  </NamedLink>
                  <span style={{ cursor: 'pointer' }}>
                    {followers?.length === 1 ? (
                      <>
                        <FormattedMessage
                          id="ProfilePage.follower"
                          values={{ count: followers?.length }}
                        />{' '}
                        &nbsp;
                      </>
                    ) : (
                      <>
                        {' '}
                        &nbsp;{' '}
                        <FormattedMessage
                          id="ProfilePage.followers"
                          values={{ count: followers?.length }}
                        />
                      </>
                    )}
                  </span>
                  &nbsp;|&nbsp;
                  <span style={{ cursor: 'pointer' }}>
                    <FormattedMessage
                      id="ProfilePage.following"
                      values={{ count: following?.length }}
                    />
                  </span>
                </div>
                <div>
                  {currentUserHasListings ? (
                    <div>
                      <div className={classNames(css.buttonSeller, css.buttonSeller2)}>
                        <span className={css.availableEarnings}>
                          <FormattedMessage id="TopbarDesktop.availableEarning" /> &nbsp;
                          {totalAmount ? (
                            <span className={css.totalAmount}>${totalAmount / 100}USD</span>
                          ) : (
                            <span>None Available Yet</span>
                          )}
                        </span>
                        {totalAmount && (
                          <span className={css.arrowRight}>
                            <IconCollection icon="iconArrowDownWhite" />
                          </span>
                        )}
                      </div>
                      <div className={css.storeItems}>
                        <NamedLink name="ManageListingsSoldPage" params={{ state: SOLD }} >
                          Sold Items ({currentUserSaleLength})
                        </NamedLink>

                        {!!currentUser && (
                          <div>
                            <NamedLink name="ProfilePage" params={{ id: currentUser.id.uuid }}>
                              <FormattedMessage id="TopbarDesktop.viewStore" />
                            </NamedLink>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className={css.buttonSeller}>
                        <NamedLink name="NewListingPage">
                          <span>
                            <FormattedMessage id="TopbarDesktop.becomeSeller" />
                          </span>
                          <span className={css.arrowRight}>
                            <IconCollection icon="iconArrowDownWhite" />
                          </span>
                        </NamedLink>
                      </div>
                      <div className={css.storeItems}>
                        {!!currentUser && (
                          <div>
                            <NamedLink name="ProfilePage" params={{ id: currentUser.id.uuid }}>
                              <FormattedMessage id="TopbarDesktop.viewStore" />
                            </NamedLink>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className={css.category}>
                {listingStores.map(store => {
                  return (
                    <div key={store.key}>
                      <div className={css.iconWrapper}>
                        <NamedLink
                          name="LandingPageListingStore"
                          params={{ type: store.key, gender: ALL_GENDER }}
                          className={css.icon}
                        >
                          <img src={store.icon} alt="icon" />
                        </NamedLink>
                      </div>
                      <span>{store.label}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </MenuItem>
        </MenuContent>
      )}
    </Menu>
  ) : null;

  const offersMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        {isAuthenticated ? (
          <div className={classNames(css.createListingLink, isAuthenticated && css.linkGap)}>
            <span className={css.cartItemWrappper}>
              <span className={css.cartCount}>{readCount > 0 ? readCount : 0}</span>
              <IconCollection icon="OfferIcon" />
            </span>
            <span className={css.createListing}>
              <FormattedMessage id="TopbarDesktop.Offers" />
            </span>
          </div>
        ) : null}
      </MenuLabel>
      <MenuContent className={css.OfferMenuContent}>
        <MenuItem key="ManageListingsPage">
          <div className={css.offerDropwownHeader}>
            <span>
              <IconCollection icon="OfferIcon" />
            </span>
            <h3>
              <FormattedMessage id="OfferNotifications.heading" />
            </h3>
            <span>|</span>
            <FormattedMessage
              id="OfferNotifications.newCount"
              values={{ count: readCount > 0 ? readCount : 0 }}
            />
          </div>

          <ul className={css.itemList}>
            {!fetchInProgress ? (
              offerTransactions?.map(toTxItem)
            ) : (
              <li className={css.listItemsLoading}>
                <IconSpinner />
              </li>
            )}
            {/* {"noResults"} */}
          </ul>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  return (
    <nav className={classes}>
      <NamedLink className={css.logoLink} name="LandingPage">
        <Logo
          format="desktop"
          className={css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
          activeCategory={activeCategory}
        />
      </NamedLink>
      {search}
      {isAuthenticated ? (
        <NamedLink
          className={classNames(css.createListingLink, isAuthenticated ? css.linkGap : css.flexRow)}
          name="FavouriteListingsPage"
        >
          <span>
            <IconCollection icon="HeartIcon" />
          </span>
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.Likes" />
          </span>
        </NamedLink>
      ) : null}
      {isAuthenticated ? offersMenu : null}
      {isAuthenticated ? (
        <NamedLink
          className={classNames(css.createListingLink, isAuthenticated && css.linkGap)}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <span>
            <IconCollection icon="alertIcon" />
          </span>
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.alert" />
          </span>
        </NamedLink>
      ) : null}
      <NamedLink
        className={classNames(
          css.createListingLink,
          !isAuthenticated && css.flexRow,
          isAuthenticated && css.linkGap
        )}
        name="NewListingPage"
      >
        <span>
          <IconCollection icon="camera" />
        </span>
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.createListing" />
        </span>
      </NamedLink>
      {/* {inboxLink} */}
      <NamedLink
        className={classNames(
          css.createListingLink,
          isAuthenticated ? css.linkGap : css.flexRow,
          css.bag
        )}
        name="CartPage"
      >
        <span className={css.cartItemWrappper}>
          <span className={css.cartCount}>
            {isAuthenticated ? (
              bookmarks && bookmarks.length > 0 ? (
                <span className={css.badge}>{bookmarks && `${bookmarks.length}`}</span>
              ) : (
                <span className={css.badge}>{lb && `${lb.length}`}</span>
              )
            ) : lb && lb.length > 0 ? (
              <span className={css.badge}>{lb && lb.length}</span>
            ) : null}
          </span>
          <IconCollection icon="bagIcon" />
        </span>
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.bag" />
        </span>
      </NamedLink>
      {profileMenu}
      {signupLink}
      {loginLink}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
