import unionWith from 'lodash/unionWith';
import config from '../../config';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { parseDateFromISO8601, getExclusiveEndDate } from '../../util/dates';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { isOriginInUse, isStockInUse } from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { getUserFavData } from '../../util/dataExtractor';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 60;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/RecentViewListingsPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/RecentViewListingsPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/RecentViewListingsPage/SEARCH_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
};

const resultIds = data => data.data.map(l => l.id);

const RecentViewListingsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    default:
      return state;
  }
};

export default RecentViewListingsPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchListings = (params, search) => async (dispatch, getState, sdk) => {
  try {
    const queryParams = parse(search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    // Add minStock filter with default value (1), if stock management is in use.
    // This can be overwriten with passed-in query parameters.
    const minStockMaybe = isStockInUse(config) ? { minStock: 1 } : {};
    const { page = 1, address, origin, ...rest } = queryParams;
    const originMaybe = isOriginInUse(config) && origin ? { origin } : {};
    await dispatch(fetchCurrentUser());
    const currentUser = getState().user.currentUser;

    const { recentlyViewedItems } = currentUser.attributes.profile.publicData || {};

    // Extract all id values into a single array
    const allIds = recentlyViewedItems?.map((e) => e.id);
    const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
    const aspectRatio = aspectHeight / aspectWidth;
    const data = {
      ids: allIds,
      // ...minStockMaybe,
      ...rest,
      ...originMaybe,
      page,
      perPage: RESULT_PAGE_SIZE,
      pub_listingStore: params.listingStore,
      include: ['author', 'images', 'currentStock'],
      'fields.listing': ['title', 'geolocation', 'price', 'description', 'publicData'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      'limit.images': 1,
    };

    dispatch(searchListingsRequest(data));
    const response = await sdk.listings.query(data);

    if (response && response.data.data) {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(response));
      return response;
    }
  } catch (error) {
    console.log(error, '&&&  &&& => error');
  }
};

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  try {
    await dispatch(searchListings(params, search));
  } catch (error) {}
};
