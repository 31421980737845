import { getBlogs } from '../../util/api';
import { getBlogSuccess } from '../BlogPage/BlogPage.duck';

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  try {
    const { blogData } = await getBlogs({});
    if (blogData && blogData.length) {
      dispatch(getBlogSuccess(blogData));
    }
  } catch (error) {
    console.log(error, '&&&  &&& => error');
  }
};
