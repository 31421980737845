import { getBlogs } from '../../util/api';

export const GET_BLOG_REQUEST = 'app/BlogPage/GET_BLOG_REQUEST';
export const GET_BLOG_SUCCESS = 'app/BlogPage/GET_BLOG_SUCCESS';

const initialState = {
  blogsData: [],
  blogInProgress: false,
};

const BlogPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case GET_BLOG_REQUEST:
      return {
        ...state,
        blogInProgress: true,
      };

    case GET_BLOG_SUCCESS:
      return {
        ...state,
        blogsData: payload.data,
        blogInProgress: false,
      };
    default:
      return state;
  }
};
export default BlogPageReducer;

export const getBlogRequest = () => ({
  type: GET_BLOG_REQUEST,
});

export const getBlogSuccess = data => ({
  type: GET_BLOG_SUCCESS,
  payload: { data },
});

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  try {
    dispatch(getBlogRequest());
    const { blogData } = await getBlogs({});
    if (blogData && blogData.length) {
      dispatch(getBlogSuccess(blogData));
    }
  } catch (error) {
    console.log(error, '&&&  &&& => error');
  }
};
